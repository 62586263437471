import { combineReducers } from 'redux';
// import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import notificationReducer from './updateReduxData';


const rootReducer = combineReducers({
  // slice1: authReducer,
  user: userReducer,
  updateData: notificationReducer,
});

export default rootReducer;