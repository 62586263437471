import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';


const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Navbar />
            <Container maxWidth="xl">
                <Box my={10}>
                    <Typography variant="h1" component="h1" gutterBottom >
                        Return Policy for The Joys Kitchen
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Last Updated: Aug 21, 2024
                    </Typography>
                    <Typography variant="body1" paragraph>
                        At The Joys Kitchen, we are committed to providing you with delicious, high-quality food. We strive to ensure that every order meets your expectations. However, if you are not satisfied with your order, we offer the following return policy:
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        1. Eligibility for Returns
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Time Frame:</strong> Due to the perishable nature of our products, returns are only accepted within 24 hours of receipt of your order.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Condition:</strong> Returns are only accepted if the food item you receive does not match the image of the food item taken by our restaurant before sending. To be eligible for a return, you must provide a clear photograph of the food item received alongside the original packaging.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Non-Returnable Items:</strong> All food items are considered final sale unless they meet the criteria outlined above. Please note that taste preferences, minor aesthetic differences, or delivery delays do not qualify for a return.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        2. How to Initiate a Return
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To initiate a return, please contact our customer service team at chjoywilliams@gmail.com within 24 hours of receiving your order. Include your order number, a detailed description of the issue, and a photograph of the food item received. We will review your request and provide you with return instructions if the return is approved.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        3. Shipping Costs
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Customer Responsibility:</strong> The customer is responsible for returning the item to us if required. However, since most returns will be processed based on photographic evidence, physical returns may not be necessary.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Prepaid Return Label:</strong> If a return is approved and requires sending the item back to us, we will provide a prepaid return shipping label.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        4. Refunds
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Processing Time:</strong> Once your return request is reviewed and approved, we will process your refund. Refunds will typically be issued to your original method of payment within 5-7 business days.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Partial Refunds:</strong> In certain situations, only partial refunds may be granted, depending on the condition of the food item and the circumstances of the return.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        5. Exchanges
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Due to the perishable nature of food, we do not offer exchanges. If your return is approved, you may choose to receive a refund or a replacement of the same item if available.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        6. Damaged or Defective Items
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you receive a food item that is damaged or defective (e.g., spoiled or contaminated), please contact us within 24 hours of receipt. We will arrange for a replacement or refund at no additional cost to you.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        7. Contact Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        For any questions or concerns about our return policy, please contact us at:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Email: chjoywilliams@gmail.com
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Address: Shop No 397, Tellapur village, Tellapur,  Rangareddy, Hyderabad, Telangana, 502032
                    </Typography>
                </Box>
            </Container>


        </Box>
    );
};

export default RefundPolicy;
