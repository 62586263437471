import { deleteAPI, getAPI, postAPI, putAPI } from "../api/api";
import { endpoints } from "../api/endpoints";

// Authorization Apis

export const registeration = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.REGISTERATION,
            payload,
            (response) => {
                console.log('Response For registeration', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While registeration', error);
                errorCallback(error)
            },
        );
    };
};

export const verifyOtp = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.VERIFY_OTP,
            payload,
            (response) => {
                console.log('Response For verify otp', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While verify otp', error);
                errorCallback(error)
            },
        );
    };
};

export const login = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.LOGIN,
            payload,
            (response) => {
                console.log('Response For Login', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While Login', error);
                errorCallback(error)
            },
        );
    };
};


export const getAllFoodItems = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        getAPI(
            endpoints.GET_ALL_FOOD_ITEMS,
            payload,
            (response) => {
                console.log('Response For getting all dishes', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting all dishes', error);
                errorCallback(error)
            },
        );
    };
};


// ORDER 

export const getCart = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.GETCART ,
            payload,
            (response) => {
                console.log('Response For add to cart', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While add to cart', error);
                errorCallback(error)
            },
        );
    };
};

export const addToCart = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.ADD_TO_CART,
            payload,
            (response) => {
                console.log('Response For add to cart', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While add to cart', error);
                errorCallback(error)
            },
        );
    };
};

export const deleteFromCart = (payload, id, callBack, errorCallback) => {
    return (dispatch) => {
        deleteAPI(
            endpoints.ADD_TO_CART + `?food_order_id=${id}`,
            payload,
            (response) => {
                console.log('Response For delete from cart', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While delete from cart', error);
                errorCallback(error)
            },
        );
    };
};

export const delivery = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.DELIVERY,
            payload,
            (response) => {
                console.log('Response For delivery', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While delivery', error);
                errorCallback(error)
            },
        );
    };
};

export const enquiry = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.ENQUIRY,
            payload,
            (response) => {
                console.log('Response For enquiry', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While enquiry', error);
                errorCallback(error)
            },
        );
    };
};

export const host = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.HOST,
            payload,
            (response) => {
                console.log('Response For enquiry', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While enquiry', error);
                errorCallback(error)
            },
        );
    };
};

export const payment = (payload, callBack, errorCallback) => {
    return (dispatch) => {
        postAPI(
            endpoints.PAYMENT,
            payload,
            (response) => {
                console.log('Response For delivery', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While delivery', error);
                errorCallback(error)
            },
        );
    };
};
