import React, { useEffect } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Banner from '../../Components/Banner.js';
import Discription from '../AboutUs/Component/Discription.js';
import Right from './Component/Right.js';
import Left from './Component/Left.js';
import FAQMAin from '../../Images/FAQBanner.jpg'
import faqImage from '../../Images/FAQ1.jpg'
import faqImage2 from '../../Images/FAQ2.jpg'
import faqImage3 from '../../Images/FAQ3.jpg'
import EnquiryForm from '../../Components/EnquiryForm.js';
import Navbar from '../../Components/Navbar.js';

const FAQ = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const faq = [
        {
            question: "What is Home's Kitchen & how does it work?",
            answer: "Home’s Kitchen is a unit of Theme Ambience Resorts & Hotels Pvt. Ltd., a company which is into the hospitality and food business since the past 15 years. Home’s Kitchen focuses on providing meal boxes with the homely touch at reasonable prices. Anyone can sign up and place an order for a meal box. We have options of daily meal, weekly & monthly packages available for breakfast, lunch & dinner. Apart from providing meal boxes, Home’s Kitchen is also into regular corporate catering (Breakfast/Lunch/Hi-Tea/Dinner)."
        },
        {
            question: "How many meals will be delivered in a Monthly/Weekly package?",
            answer: "Monthly package – A month is considered as 4 weeks. For eg. - If a 6-day week package is taken, meals would be delivered from Monday to Saturday for 4 weeks, i.e., 24 meals would be delivered. Weekly package – 5 days in a week will be from Monday to Friday and 6 days a week will be from Monday to Saturday and 7 days a week will be delivered on all days of the week."
        },
    ];

    return (
        <Box >
            <Navbar />

            <Banner title={"FAQ"} image={FAQMAin} />
            <Right title={"General"} qa={faq} image1={faqImage} image2={faqImage} />
            <Left title={"Delivery"} qa={faq} image1={faqImage2} image2={faqImage2} />
            <Right title={"Others"} qa={faq} image1={faqImage3} image2={faqImage3} />
            <EnquiryForm />

        </Box >
    );
};

export default FAQ;
