import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import MealCard from '../../../Components/MealCard.js';
import theme from '../../../theme.js';
import Background2 from '../../../Images/Background2.svg';
import { useTheme } from '@emotion/react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch } from 'react-redux';
import { getAllFoodItems } from '../../../api/api/action.js';
import CustomLoader from '../../../Components/CustomLoader.js';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const MealOptions = ({ title, data }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    let centerSlidePercentage = 100;
    if (isXs) {
        centerSlidePercentage = 100;
    } else if (isSm) {
        centerSlidePercentage = 50;
    } else if (isMd) {
        centerSlidePercentage = 33.33;
    } else if (isLg) {
        centerSlidePercentage = 25;
    }

    return (
        <Box sx={{ px: { xs: 3, sm: 6, md: 12 }, minHeight: { md: "70vh", xs: "50vh" }, py: { xs: 5, md: 5 }, position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <CustomLoader open={isLoader} setOpen={setIsLoader} />
            {!isMobile && (
                <img
                    src={Background2}
                    style={{
                        width: '25vw',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'absolute',
                        top: -10,
                        left: 100,
                        zIndex: -10
                    }}
                />
            )}
            <Typography variant="body1" sx={{ ml: { xs: 0, md: 13 }, textAlign: { xs: 'center', md: 'left' } }}>
                Meal Option
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, alignItems: 'center', verticalAlign: 'middle' }}>
                <Divider sx={{ width: '7rem', background: '#000', height: '1px', mr: { xs: 2, md: 2 }, mb: { xs: 2, md: 0 } }} />
                <Typography data-aos="fade-up" data-aos-once={true} variant="h1" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    {title}
                </Typography>
            </Box>

            <Box sx={{ mt: 3}} data-aos="fade-up" data-aos-once={true}> 
                {data?.length > 0 ? (
                    <Carousel
                        NextIcon={<NavigateNextIcon style={{ color: '#FFCC00' }} />}
                        PrevIcon={<NavigateBeforeIcon style={{ color: '#FFCC00' }} />}
                        navButtonsProps={{
                            style: {
                                backgroundColor: 'transparent',
                                borderRadius: 0,
                            }
                        }}
                        navButtonsWrapperProps={{
                            style: {
                                bottom: '0',
                                top: 'unset',
                            }
                        }}
                        indicatorIconButtonProps={{
                            style: {
                                display: 'none'
                            }
                        }}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay
                        interval={2000}
                        showArrows={true}
                        centerMode
                        centerSlidePercentage={centerSlidePercentage}
                        swipeable
                        emulateTouch
                        className="custom-carousel"
                    >
                        {data.reduce((acc, item, index) => {
                            const itemsPerSlide = isXs ? 1 : isSm ? 2 : isMd ? 3 : isLg ? 4 : 1;
                            if (index % itemsPerSlide === 0) acc.push([]);
                            acc[acc.length - 1].push(item);
                            return acc;
                        }, []).map((slideItems, slideIndex) => (
                            <Grid container key={slideIndex} spacing={2}>
                                {slideItems.map((item, itemIndex) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={itemIndex}>
                                        <MealCard item={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Carousel>
                ) : (
                    <Box sx={{ display:'flex', justifyContent:"center", alignItems:'center' }}>
                    <Typography variant="h4" className='fontAlpha' sx={{ textAlign: 'center', mt: 5, fontWeight: 400 }}>
                        No food data available yet
                    </Typography>
                    </Box>

                )}
            </Box>
        </Box>
    );
};

export default MealOptions;
