import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Drawer, List, ListItem, ListItemText, Box, Badge, Snackbar, Alert, Divider, Modal } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import LoginModal from './LoginModal';
import RegistrationModal from './RegisterationModal';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CustomSnackbar from './CustomSnackbar';
import { getCart } from '../api/api/action';
import { useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from "../Images/logo.png"
import logoBlack from "../Images/logoBlack.png"

const Navbar = () => {
    const userId = localStorage.getItem("user_id") || null;
    const foodOrderId = localStorage.getItem("foodOrderIds")
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const [openLogin, setOpenLogin] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [cartItem, setCartItem] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    const dispatch = useDispatch();

    function arrayToCommaSeparatedString(arr) {
        // Ensure arr is an array
        if (!Array.isArray(arr)) {
            // If it's a string, split by commas or handle it as needed
            if (typeof arr === 'string') {
                arr = arr.split(',').map(item => item.trim());
            } else {
                // Default to an empty array if it's neither an array nor a string
                arr = [];
            }
        }
        return arr.join(', ');
    }
    useEffect(() => {
        setAccessToken(localStorage.getItem("accessToken"))
    }, [updated]);

    const handleLogoutOpen = () => {
        setOpenLogout(true);
    };

    const handleLogoutClose = () => {
        setOpenLogout(false);
    };

    const handleLogoutConfirm = () => {
        // Remove specific items from localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");
        localStorage.removeItem("fullName");
        localStorage.removeItem("phone");
        localStorage.removeItem("user_id");
        setUpdated(prevState => !prevState);
        // Update the state and navigate
        setAccessToken(null);
        handleLogoutClose();
        // navigate('/');
    };


    useEffect(() => {
        const payload = {
            // "user_id": userId === "" ? null : userId,
            "food_order_id": foodOrderId
          };
         
    
        dispatch(
            getCart(payload, 
                (data) => {
                    setCartItem(data?.data);
                },
                (err) => {
                    console.log(err);
                }
            )
        );
    }, [dispatch, userId, updated]); 
    

    const subtotal = cartItem?.reduce((acc, order) => {
        const quantity = Number(order.meal_quantity) || 0;
        const price = Number(order.food_price) || 0;
        return acc + (price * quantity);
    }, 0);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleRegister = () => {
        setOpenRegister(false);
    };

    const handleLogin = () => {
        setOpenLogin(false);
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'About us', path: '/about-us' },
        { text: 'Meal Service', path: '/meal-service' },
        { text: 'Home', path: '/' },
        { text: 'Catering Service', path: '/catering-service' },
        { text: 'FAQ', path: '/faq' },
    ];

    return (
        <Box >
            <LoginModal open={openLogin} handleClose={handleLogin} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} setSnackbarOpen={setSnackbarOpen} setUpdated={setUpdated} />
            <RegistrationModal open={openRegister} handleClose={handleRegister} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} setSnackbarOpen={setSnackbarOpen} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Box>
                {!isMobile && (
                    <AppBar
                        position="static"
                        elevation={0}
                        color={'transparent'}
                        sx={{
                            borderBottom: '1px solid #fff',
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center'
                        }}
                    >
                        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box>
                            <img
                                                src={logoBlack}
                                                alt="Logo"
                                                style={{ height: '80px', cursor: 'pointer' }}
                                                onClick={() => navigate('/')}
                                            />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, }}>
                                {menuItems.map((item) => (
                                    <Box
                                        key={item.text}
                                        color="inherit"
                                        disableRipple
                                        sx={{
                                            mx: { md: 0, lg: 2 },
                                            cursor: 'pointer',
                                            px: { md: 2, sm: 0 },
                                            // background: location.pathname === item.path ? '#AF431A' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'none',
                                                color: theme.palette.text.brown
                                            }
                                        }}
                                        onClick={() => { navigate(item.path) }}
                                    >
                                        {/* {item.text === 'Logo' ? (
                                            <img
                                                src={logoBlack}
                                                alt="Logo"
                                                style={{ height: '80px', cursor: 'pointer' }}
                                                onClick={() => navigate('/')}
                                            />
                                        ) : ( */}
                                            <Typography
                                                variant='caption'
                                                sx={{
                                                    flexGrow: 1,
                                                    fontSize: location.pathname === item.path ? { md: '1.2rem', sm: "1rem" } : '0.9rem',
                                                    fontWeight: 600,
                                                    color: location.pathname === item.path ? theme.palette.text.brown : 'inherit',
                                                }}
                                            >
                                                {item.text}
                                            </Typography>
                                        {/* )} */}
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{ textAlign: 'right', display: 'flex', cursor: 'pointer', }}>
                                {accessToken ?
                                    <Box onClick={() => { navigate("/cart") }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', ml: 3 }}>
                                            <Badge badgeContent={cartItem?.length} color="error"> {/* Replace 4 with your dynamic value */}
                                                <ShoppingCartIcon sx={{ color: theme.palette.text.brown }} />
                                            </Badge>
                                            <Typography variant='body2' sx={{ ml: 2 }}>
                                                Rs. {subtotal ? subtotal : "0"}
                                            </Typography>
                                            <Divider orientation="vertical" flexItem sx={{ background: theme.palette.background.brown, height: '5vh', mx: 1, mt: 0.3 }} />
                                            <IconButton onClick={handleLogoutOpen}>
                                                <LogoutIcon sx={{ color: theme.palette.text.brown }} />
                                            </IconButton>
                                        </Box>

                                    </Box>

                                    :
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant='h6' onClick={() => setOpenLogin(true)} >
                                            Login
                                        </Typography>
                                        <Typography variant='h6' onClick={() => setOpenRegister(true)}>
                                            / Register
                                        </Typography>
                                    </Box>
                                }


                            </Box>

                        </Toolbar>
                    </AppBar>
                )}
            </Box>

            {isMobile && (
                <AppBar position="static" elevation={0} color={'transparent'} sx={{ borderBottom: '1px solid #000' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <img
                                src={logoBlack}
                                alt="Logo"
                                style={{ height: '80px', cursor: 'pointer' }}
                                onClick={() => navigate('/')}
                            />
                        </Box>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                              <MenuIcon sx={{ fontSize: "35px" }} />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <List>
                                {menuItems.map((item) => (
                                    <ListItem button key={item.text} component={Link} to={item.path} onClick={toggleDrawer(false)}>
                                        <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
                                            {item.text}
                                        </Typography>
                                    </ListItem>
                                ))}
                                <ListItem button onClick={() => { setOpenLogin(true); toggleDrawer(false)(); }}>
                                    <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
                                        Login
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => { setOpenRegister(true); toggleDrawer(false)(); }}>
                                    <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
                                        Register
                                    </Typography>
                                </ListItem>
                                <ListItem button onClick={() => { navigate("/cart"); toggleDrawer(false)(); }}>
                                    <Badge badgeContent={cartItem?.length} color="error">
                                        <ShoppingCartIcon sx={{ color: theme.palette.text.brown }} />
                                    </Badge>
                                    <Typography variant='caption' sx={{ ml: 2 }}>
                                        Rs. {subtotal ? subtotal : "0"}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Drawer>
                    </Toolbar>
                </AppBar>
            )}

            <Modal
                open={openLogout}
                onClose={handleLogoutClose}
                aria-labelledby="logout-modal-title"
                aria-describedby="logout-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 1, boxShadow: 24 }}>
                    <Typography id="logout-modal-title" variant="h6" component="h2">
                        Confirm Logout
                    </Typography>
                    <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to logout?
                    </Typography>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleLogoutClose} sx={{ mr: 1, color: theme.palette.text.black }}>
                            Cancel
                        </Button>
                        <Button disableElevation onClick={handleLogoutConfirm} color="primary" variant="contained">
                            Logout
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </Box>
    );
};

export default Navbar;
