import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import theme from '../theme';
import messageIcon from '../Images/messageIcon.svg';
import { FaFacebook, FaInstagram, FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import logo from "../Images/logo.png"


const Footer = () => {
    const navigate = useNavigate();

    const quickLinks = [
        { text: 'FAQ', path: '/faq' },
        { text: 'About us', path: '/about-us' },
        { text: 'Pricing', path: '/' },
        { text: 'Terms & Conditions', path: '/terms&conditions' },
        { text: 'Privacy Policy', path: '/privacy-policy' },
        { text: 'Shipping Policy', path: '/shipping-policy' },
        { text: 'Cancellation and Refund Policy', path: '/refund-policy' },
    ];

    // const quickLinks = [
    //     "FAQ",
    //     "About us",
    //     "Contact us",
    //     "Pricing",
    //     "Terms & Conditions",
    //     "Privacy Policy",
    //     "Cancellation and Refund Policy",
    // ];

    const phoneNumbers = [
        "+91 70938 57806",
    ];

    return (
        <Box>


            <Box sx={{ px: { md: 12, sm: 6, xs: 3 }, background: theme.palette.background.black, pt: 7, pb: 2, borderBottom: '1px solid #fff', textAlign: { md: "left", xs: "left" } }}>
                <Grid container spacing={4}>
                    <Grid item md={6} sm={12} xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <img src={logo} alt="The Joys Kitchen Logo" style={{ width: '150px', marginBottom: '16px' }} />
                        <Typography variant="caption" className='fontGama' sx={{ color: theme.palette.text.white, ml: 1 }}>
                            Shop No 397, Tellapur village, Tellapur,  Rangareddy, Hyderabad, Telangana, 502032
                        </Typography>
                        <Box sx={{ mt: 2, ml: 1 }}>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
                                <FaFacebook style={{ color: theme.palette.text.white }} />
                            </a>
                            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
                                <IoLogoWhatsapp style={{ color: theme.palette.text.white }} />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
                                <FaInstagram style={{ color: theme.palette.text.white }} />
                            </a>
                        </Box>
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="h6" className='fontAlpha' sx={{ mt: 1, color: theme.palette.text.default, mb: 2, fontWeight: 400 }}>
                            QUICK LINKS
                        </Typography>
                        {quickLinks.map((item, index) => (
                            <Typography key={index} onClick={() => { navigate(item.path) }} variant="caption" className='fontGama' sx={{
                                color: theme.palette.text.white, mb: 1, fontWeight: 400, display: 'block', fontSize: '0.8rem', cursor: 'pointer',
                                '&:hover': {
                                    color: theme.palette.text.default,
                                },
                            }}>
                                {item.text}
                            </Typography>
                        ))}
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <Typography variant="h6" className='fontAlpha' sx={{ mt: 1, color: theme.palette.text.default, mb: 2, fontWeight: 400 }}>
                            REACH US
                        </Typography>
                        <Box className="center" sx={{ justifyContent: { md: "left", xs: "center" }, display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                            <img src={messageIcon} alt="Message Icon" />
                            <a href="mailto:georgia.young@example.com" style={{ textDecoration: 'none' }}>
                                <Typography variant="caption" className='fontGama' sx={{
                                    color: theme.palette.text.white, fontWeight: 400, cursor: 'pointer', ml: 1,
                                    '&:hover': {
                                        color: theme.palette.text.default,
                                    },
                                }}>
                                    chjoywilliams@gmail.com
                                </Typography>
                            </a>
                        </Box>
                        {phoneNumbers.map((item, index) => (
                            <Box key={index} className="center" sx={{ justifyContent: { md: "left", xs: "left" }, display: 'flex', alignItems: 'center', mt: 1 }}>
                                <FaPhoneAlt style={{ color: theme.palette.text.white }} />
                                <a href={`tel:${item}`} style={{ textDecoration: 'none' }}>
                                    <Typography variant="caption" className='fontGama' sx={{
                                        color: theme.palette.text.white, fontWeight: 400, cursor: 'pointer', ml: 1, '&:hover': {
                                            color: theme.palette.text.default,
                                        },
                                    }}>
                                        {item}
                                    </Typography>
                                </a>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ background: theme.palette.text.black, textAlign: 'center', py: 3 }}>
                <Typography variant="caption" className='fontGama' sx={{ color: theme.palette.text.white }}>
                    Copyright © 2024 Firm_name. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
