const actionTypes = {
    NEW_NOTIFICATION: 'NEW_NOTIFICATION',
};

export const newNotification = (notification) => ({
    type: actionTypes.NEW_NOTIFICATION,
    payload: notification,
});

const initialState = {
    notifications: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEW_NOTIFICATION:
            return {
                ...state,
                notifications: [action.payload, ...state.notifications],
            };
        default:
            return state;
    }
};

export default notificationReducer;