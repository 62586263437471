import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../../theme.js';
import mealImage from '../../../Images/mealImage.jpg';
import { useNavigate } from "react-router-dom";

const Discover = ({ title }) => {
    const navigate = useNavigate();


    const ImageContainer = styled(Box)({
        position: 'relative',
        width: 'fit-content',
        margin: 'auto',
    });

    const LargeImage = styled('img')(({ theme }) => ({
        width: '25rem', // Adjust size as needed
        height: '25rem',
        borderRadius: '8px',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            width: '18rem',
            height: '18rem',
        },
        [theme.breakpoints.down('sm')]: {
            width: '14rem',
            height: '14rem',
        },
    }));

    const SmallImage = styled('img')(({ theme }) => ({
        width: '10rem', // Adjust size as needed
        height: '10rem',
        position: 'absolute',
        bottom: '-100px',
        left: '-100px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            width: '8rem',
            height: '8rem',
            bottom: '-80px',
            left: '-80px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '6rem',
            height: '6rem',
            bottom: '-60px',
            left: '-60px',
        },
    }));

    return (
        <Box sx={{ px: { xs: 3, sm: 6, md: 12 }, minHeight: {md:"100vh", xs:"100vh"},  background: theme.palette.background.black, display: 'flex', justifyContent: 'center', flexDirection: 'column', py:5 }}>
            <Grid container spacing={5}>
                <Grid item md={6} sm={12} xs={12} data-aos="fade-up" data-aos-once={true}>
                    <Typography variant="h4" className='fontGama' sx={{ ml: { xs: 0, md: 13 }, color: theme.palette.text.white, textAlign: { xs: 'center', md: 'left' }, fontWeight:400 }}>
                        Discover
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                        <Divider sx={{ width: '5rem', background: theme.palette.background.default, height: '1px', mt: 3, mr: { xs: 0, md: 4 }, mb: { xs: 2, md: 0 } }} />
                        <Box>
                            <Typography variant="h1" sx={{ color: theme.palette.text.default, width: { xs: '100%', md: '20rem' }, textAlign: { xs: 'center', md: 'left' } }}>
                                {title}
                            </Typography>
                            <Typography variant="h6" className='fontBeta' sx={{ color: theme.palette.text.white, width: { xs: '100%', md: '100%' }, textAlign: { xs: 'center', md: 'left' }, mt: 2, fontWeight:400 }}>
                                We provide nutritious & delicious homely meals cooked with the utmost care by our expert chefs and we are amongst the best caterers in Hyderabad. The meal combos from our catering services in Hyderabad come with different menus every day and the light on stomach rice bowls shall surely delight your taste buds. The meals come in various packages and are delivered across various locations in Hyderabad.
                            </Typography>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, flexWrap: 'wrap' }}>
                                <Button variant="filled" className='fontBeta' sx={{ mb: { xs: 2, md: 0 } }}>
                                    <Typography variant="h5" className='fontBeta' sx={{color:theme.palette.text.black}} onClick={() => { navigate("/meal-service") }}>
                                        Order now
                                    </Typography>
                                </Button>

                                <Button variant="outlined" className='fontBeta' sx={{
                                    borderColor: "#fff",
                                    ml: { xs: 2, md: 2 },
                                    mb: { xs: 2, md: 0 },
                                    fontWeight:400,
                                    '&:hover': {
                                        borderColor: '#fff',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                    },
                                }}
                                onClick={() => { navigate("/meal-service") }}
                                >
                                    <Typography variant="h5" className='fontBeta' sx={{ color: theme.palette.text.white, fontWeight:400 }}>
                                        Order now
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                    <ImageContainer data-aos="zoom-in" data-aos-once={true}>
                        <LargeImage src={mealImage} alt="Large Food" />
                        <SmallImage src={mealImage} alt="Small Food" />
                    </ImageContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Discover;
