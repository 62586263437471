import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';


const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Navbar />
            <Container maxWidth="xl" sx={{my:10 }}>
                <Typography variant="h1" component="h1" gutterBottom>
                    Terms and Conditions for The Joys Kitchen
                </Typography>
                <Typography variant="h6" gutterBottom>
                        Last Updated: Aug 21, 2024
                    </Typography>

                <Box mt={3}>
                    <Typography variant="body1" gutterBottom>
                        Welcome to The Joys Kitchen! By accessing and using our website thejoyskitchen.in, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before placing an order.
                    </Typography>

                    <List>
                        <ListItem>
                            <Typography variant="h5">1. Acceptance of Terms</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                By using this website, you agree to these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website or services.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">2. Use of the Website</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Account Information:</strong> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">3. Product Information</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Menu Items:</strong> All products, including prices, descriptions, and availability, are subject to change without notice. We reserve the right to modify or discontinue any product at any time.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">4. Pricing and Payment</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Prices:</strong> All prices listed on our website are subject to applicable taxes and fees. We reserve the right to change prices at any time without prior notice.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Payment Methods:</strong> We accept list of payment methods, e.g., credit/debit cards, Net Banking, UPI, etc.. Payment must be made at the time of order.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">5. Order Acceptance</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Order Confirmation:</strong> After placing an order, you will receive an order confirmation email. This email does not guarantee acceptance of your order. We reserve the right to cancel or refuse any order at our discretion.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">6. Delivery and Shipping</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Delivery Area:</strong> Please refer to our <Link to="shipping-policy" underline="hover">Shipping Policy</Link> for information on our delivery area, shipping options, and delivery times.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Delivery Issues:</strong> We are not responsible for delays or failures in delivery due to circumstances beyond our control, such as weather, traffic, or incorrect delivery information provided by the customer.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">7. Returns and Refunds</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Return Policy:</strong> Please refer to our <Link to="/refund-policy" underline="hover">Return Policy</Link> for information on how to handle returns, refunds, and exchanges.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Refund Processing:</strong> Refunds will be processed according to our Return Policy and will be credited to your original method of payment within number of days, e.g., 7-10 business days.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">8. Limitation of Liability</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                To the fullest extent permitted by law, The Joys Kitchen shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our website or services, including but not limited to damages for loss of profits, data, or other intangible losses.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">9. Intellectual Property</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Ownership:</strong> All content on this website, including text, graphics, logos, images, and software, is the property of The Joys Kitchen or its content suppliers and is protected by intellectual property laws.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Usage Restrictions:</strong> You may not reproduce, distribute, modify, or otherwise exploit any content on this website without prior written consent from The Joys Kitchen.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">10. Changes to Terms and Conditions</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of the website after any changes signifies your acceptance of the revised terms.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">11. Contact Information</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                If you have any questions or concerns about our Terms and Conditions, please contact us at:
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Email:</strong> chjoywilliams@gmail.com
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Phone:</strong> +91 70938 57806
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Address:</strong> Shop No 397, Tellapur village, Tellapur,  Rangareddy, Hyderabad, Telangana, 502032
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
            </Container>


        </Box>
    );
};

export default TermsAndConditions;
