import React from 'react';
import { Box, Typography } from '@mui/material';
import bannerImage from '../Images/bannerImage.webp';
import theme from '../theme';

const Banner = ({ title, image }) => {
    return (
        <Box
            sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: { xs: '40vh', md: '50vh', sm:"50vh" }, // Adjust height for different screen sizes
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                '::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
                    zIndex: 1, // Place the shadow overlay behind the content
                },
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    color: theme.palette.text.white,
                    position: 'relative', // Ensure the text is above the shadow overlay
                    zIndex: 2,
                    fontSize: { xs: '2rem', md: '3rem' }, // Adjust font size for different screen sizes
                    textAlign: 'center',
                    px: { xs: 2, md: 0 }, // Add padding for smaller screens
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default Banner;
