import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import { Box } from '@mui/material';
import Template from './Components/Template';
import MealOptions from './Components/MealOptions';
import Discover from './Components/Discover';
import OrderCounts from './Components/OrderCounts';
import Footer from '../../Components/Footer';
import TestimonialsCarousel from './Components/Testimonial';
import RegistrationModal from '../../Components/RegisterationModal';
import LoginModal from '../../Components/LoginModal';
import HomeBackground from '../../Images/HomeBackground.svg'
import Background2 from '../../Images/Background2.svg'
import { getAllFoodItems } from '../../api/api/action';
import { useDispatch } from 'react-redux';

const Home = () => {
  const [open, setOpen] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [meals, setMeals] = useState([]);
  const [breakfast, setBreakfast] = useState([]);
  const [riceBowl, setRiceBowl] = useState([]);
  const [mealBox, setMealBox] = useState([]);
  const [addOns, setAddOns] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    const payload = null

    setIsLoader(true)

    dispatch(
      getAllFoodItems(payload,
        (data) => {
          setIsLoader(false)
          setMeals(data?.data)
          setBreakfast(data?.data?.filter(meal => meal?.food_category === "Breakfast"));
          setRiceBowl(data?.data?.filter(meal => meal?.food_category === "Rice Bowl"));
          setMealBox(data?.data?.filter(meal => meal?.food_category === "Royal Meal Box"));
          setAddOns(data?.data?.filter(meal => meal?.food_category === "Add Ons"));

        },
        (err) => {
          setIsLoader(false)
          console.log(err)
        }
      )
    );
  }, [])

  return (
    <Box>
      <Navbar />

      <Box
        sx={{
          backgroundImage: { lg: `url(${HomeBackground})`, sm: `url(${HomeBackground})`, xs: 'none', },
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '90vh'
        }}> */}
        <Template />
        {/* </Box> */}
      </Box>
      <MealOptions title={"BreakFast"} data={breakfast} />
      <MealOptions title={"Meal Combo"} data={mealBox} />
      <MealOptions title={"Rice Bowl"} data={riceBowl} />
      <MealOptions title={"Royal Meal Box"} data={mealBox} />
      <MealOptions title={"Add Ons"} data={addOns} />
      <Discover title={"Tasty Vegetarian Homely Food"} />
      <OrderCounts title={"Regular Catering | Breakfast Box | Snack Box | Sweets & Namkeen"} />
      <TestimonialsCarousel title={"Our Happy Clients"} />
      {/* <RegistrationModal open={open} handleClose={handleClose} /> */}
      {/* <LoginModal open={open} handleClose={handleClose}/>    */}
    </Box>
  );
};

export default Home;
