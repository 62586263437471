import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Grid, InputLabel, TextareaAutosize, Snackbar, Alert, Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { delivery, payment } from '../../api/api/action';
import CustomLoader from '../../Components/CustomLoader';
import axios from 'axios';

const Checkout = ({ open, handleClose, gst, subTotal, total }) => {
    const userId = localStorage.getItem("user_id");
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [landmark, setLandmark] = useState('');
    const [postCode, setPostCode] = useState('');
    const [remarks, setRemarks] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!localStorage.getItem("accessToken")) {
            const errorMessage = 'Please Login';
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!name || !phone || !email || !fromDate || !fullAddress || !landmark || !postCode || !remarks) {
            setSnackbarMessage("Please fill all fields");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            "user": userId,
            "full_name": name,
            "phone": phone,
            "landmark": landmark,
            "pincode": postCode,
            "delivery_instructions": remarks,
            "email": email,
            "from_date": fromDate,
            "full_address": fullAddress
        };

        setIsLoader(true);

        dispatch(
            delivery(payload,
                (data) => {
                    setIsLoader(false);
                    console.log(data);
                    setSnackbarMessage("Please proceed to Payment ");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    // setName('');
                    // setPhone('');
                    // setEmail('');
                    // setFromDate('');
                    // setFullAddress('');
                    // setLandmark('');
                    // setPostCode('');
                    // setRemarks('');
                    window.location.href = 'https://api.thejoyskitchen.in/kitchen/pay/';
                 
                    // navigate("/cart")
                },
                (err) => {
                    setIsLoader(false);
                    console.log(err);
                    setSnackbarMessage(err?.response?.data?.msg);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            aria-labelledby="checkout-dialog-title"
        >
            <DialogContent>
                <CustomLoader open={isLoader} setOpen={setIsLoader} />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: 4,
                        background: theme.palette.background.white
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: 'left' }}>
                                        <Typography variant='h4' className='fontAlpha'>Add Delivery Address</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel htmlFor="name">Name*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel htmlFor="phone">Phone*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel htmlFor="email">Email*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel htmlFor="fromDate">From Date*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="fromDate"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="fullAddress">Full Address*</InputLabel>
                                        <TextareaAutosize
                                            id="fullAddress"
                                            value={fullAddress}
                                            onChange={(e) => setFullAddress(e.target.value)}
                                            minRows={4}
                                            style={{
                                                width: '97%',
                                                background: theme.palette.background.white,
                                                borderRadius: "6px",
                                                padding: '16.5px 14px',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                fontSize: '1rem',
                                                lineHeight: '1.4375em',
                                                letterSpacing: '0.00938em'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="landmark">Landmark*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="landmark"
                                            value={landmark}
                                            onChange={(e) => setLandmark(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <InputLabel htmlFor="postCode">Post Code*</InputLabel>
                                        <TextField
                                            sx={{
                                                background: theme.palette.background.white,
                                                borderRadius: "6px"
                                            }}
                                            fullWidth
                                            id="postCode"
                                            value={postCode}
                                            onChange={(e) => setPostCode(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="remarks">Remarks*</InputLabel>
                                        <TextareaAutosize
                                            id="remarks"
                                            value={remarks}
                                            onChange={(e) => setRemarks(e.target.value)}
                                            minRows={4}
                                            style={{
                                                width: '97%',
                                                background: theme.palette.background.white,
                                                borderRadius: "6px",
                                                padding: '16.5px 14px',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                fontSize: '1rem',
                                                lineHeight: '1.4375em',
                                                letterSpacing: '0.00938em'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box p={2} sx={{
                                border: ".1rem dashed #d7d7d7",
                                backgroundColor: "#f9f9f9",
                                borderRadius: ".3rem",
                            }}>
                                <Typography>Cart Total</Typography>
                                <hr />
                                <Typography my={2}>SubTotal: <Box component={"span"} sx={{ float: "right" }}>Rs. {subTotal}</Box></Typography>
                                <Typography my={2}>GST (18%): <Box component={"span"} sx={{ float: "right" }}>Rs. {gst}</Box></Typography>
                                <Typography my={2}>Total: <Box component={"span"} sx={{ float: "right" }}>Rs. {total}</Box></Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: 'right' }}>
                            <Button disableElevation onClick={handleClose} color="secondary" sx={{ mr: 5 }}>Cancel</Button>
                            <Button disableElevation onClick={handleSubmit} type="submit" variant="contained" color="primary">Proceed for Payment</Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
};

export default Checkout;
