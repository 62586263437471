import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { login } from "../api/api/action";
import { useDispatch } from 'react-redux';
import CustomLoader from "./CustomLoader";

const LoginModal = ({ open, handleClose, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, setUpdated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      "email": email,
      "password": password,

    }

    setIsLoader(true)

    dispatch(
      login(payload,
        (data) => {
          setIsLoader(false)
          console.log(data)
          setEmail("")
          setPassword("")
          setSnackbarMessage("Welcome! Login Successful");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          localStorage.setItem("accessToken", data?.data?.access)
          localStorage.setItem("email", data?.data?.email)
          localStorage.setItem("fullName", data?.data?.full_name)
          localStorage.setItem("phone", data?.data?.phone)
          localStorage.setItem("user_id", data?.data?.user_id)
          setUpdated(prevState => !prevState);
          handleClose();
        },
        (err) => {
          setIsLoader(false)
          console.log(err)
          setSnackbarMessage(err?.response?.data?.msg);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
    handleClose(); // Close the modal on successful login (replace with actual logic)
  };


  return (
    <Dialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose}>
      {/* <DialogTitle>
        <Typography sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose}>
          <HighlightOff />
        </Typography>
      </DialogTitle> */}
      <DialogContent>
        <Box px={3} pb={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'middle', alignItems: 'center', my: 1 }}>
            <Typography variant="h6" gutterBottom>Login</Typography>
            <HighlightOff onClick={handleClose} sx={{ cursor: 'pointer' }} />

          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button disableElevation type="submit" variant="contained" fullWidth>
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

      </DialogContent>
      <CustomLoader open={isLoader} setOpen={setIsLoader} />

    </Dialog>
  );
};

export default LoginModal;
