import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography, Snackbar, Alert, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import { registeration, verifyOtp } from "../api/api/action";
import { useDispatch } from 'react-redux';
import CustomLoader from "./CustomLoader";

const RegistrationModal = ({ open, handleClose,  setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity  }) => {
    const [fullName, setFullName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [receiveUpdates, setReceiveUpdates] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({
        fullName: false,
        mobileNumber: false,
        email: false,
        password: false,
        otp: false,
    });

    const handleSendOtp = () => {
        // Validate the fields before sending OTP
        if (!validateFields()) {
            return;
        }
        if (!validateFields()) {
            return;
        }

        const payload = {
            "full_name": fullName,
            "email": email,
            "phone": mobileNumber,
            "password": password,
            "recieve_updates": receiveUpdates,
            // "gender":"Male"
        }

        setIsLoader(true)

        dispatch(
            registeration(payload,
                (data) => {
                    setIsLoader(false)
                    console.log(data)
                    // setFullName("")
                    // setEmail("")
                    // setMobileNumber("")
                    // setPassword("")
                    setOtpSent(true);
                    setSnackbarMessage("OTP Sent. Please Check Your Email");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                },
                (err) => {
                    setIsLoader(false)
                    console.log(err)
                }
            )
        );

        // Dummy OTP send logic (replace with actual logic)

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Validate all fields
        const payload = {
            "email": email,
            "otp": otp,

        }

        setIsLoader(true)

        dispatch(
            verifyOtp(payload,
                (data) => {
                    setIsLoader(false)
                    console.log(data)
                    setFullName("")
                    setEmail("")
                    setMobileNumber("")
                    setPassword("")
                    setOtp("")
                    setSnackbarMessage("Registeration Completed. Please Login now");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    handleClose(); 
                },
                (err) => {
                    setIsLoader(false)
                    console.log(err)
                    setSnackbarMessage(err?.response?.data?.msg);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
        // Dummy submission logic (replace with actual logic)

    };

    const validateFields = () => {
        let valid = true;
        const errors = {
            fullName: false,
            mobileNumber: false,
            email: false,
            password: false,
            otp: false,
        };

        // Validate Full Name
        if (!fullName.trim()) {
            errors.fullName = true;
            valid = false;
        }

        // Validate Mobile Number (basic check for 10 digits)
        if (!/^\d{10}$/.test(mobileNumber)) {
            errors.mobileNumber = true;
            valid = false;
        }

        // Validate Email (basic format check)
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            errors.email = true;
            valid = false;
        }

        // Validate Password (min length)
        if (password.length < 6) {
            errors.password = true;
            valid = false;
        }

        // Validate OTP (if OTP is required and sent)
        if (otpSent && !otp.trim()) {
            errors.otp = true;
            valid = false;
        }

        setValidationErrors(errors);

        if (!valid) {
            setSnackbarMessage("Please fill out all fields correctly.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }

        return valid;
    };

    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
            {/* <DialogTitle>
                <Typography sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose}>
                    <HighlightOff />
                </Typography>
            </DialogTitle> */}
            <DialogContent>
                <Box px={3} pb={2}>
                    <Box className="center" sx={{ alignItems: "center", verticalAlign: 'middle' }}>
                        <Typography variant="h6" gutterBottom>Register</Typography>
                        <IconButton onClick={handleClose}>
                            <HighlightOff />
                        </IconButton>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    placeholder="Full Name"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    required
                                    error={validationErrors.fullName}
                                    helperText={validationErrors.fullName && "Please enter your full name."}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    placeholder="Mobile Number"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    required
                                    type="tel"
                                    inputProps={{ pattern: "[0-9]{10}" }} // Validating mobile number
                                    error={validationErrors.mobileNumber}
                                    helperText={validationErrors.mobileNumber && "Please enter a valid 10-digit mobile number."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    type="email"
                                    error={validationErrors.email}
                                    helperText={validationErrors.email && "Please enter a valid email address."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    type="password"
                                    error={validationErrors.password}
                                    helperText={validationErrors.password && "Password must be at least 6 characters long."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={receiveUpdates}
                                            onChange={(e) => setReceiveUpdates(e.target.checked)}
                                            name="receiveUpdates"
                                        />
                                    }
                                    label="Would you like to receive future updates?"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" fullWidth onClick={handleSendOtp}>
                                    Send OTP
                                </Button>
                            </Grid>
                            {otpSent && (
                                <Grid item xs={12}>
                                   <TextField
                                        fullWidth
                                        placeholder="OTP"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        required
                                        type="text"
                                        inputProps={{ maxLength: 6, pattern: "[0-9]*" }} // Limiting OTP to 6 digits and numbers only
                                        error={validationErrors.otp}
                                        helperText={validationErrors.otp && "Please enter the OTP."}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" fullWidth>
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>

            <CustomLoader open={isLoader} setOpen={setIsLoader}/>
        </Dialog>
    );
};

export default RegistrationModal;
