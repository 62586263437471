import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import theme from '../../../theme.js';
import CountUp from 'react-countup';
import { useNavigate } from "react-router-dom";
import { useInView } from 'react-intersection-observer';


const CounterBox = ({ end, label }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Only trigger once when it becomes visible
    threshold: 0.3, // Trigger when 30% of the component is visible
});

return (
    <Box
        className="center"
        ref={ref}
        sx={{
            border: '1px solid #fff',
            borderRadius: '8px',
            textAlign: 'center',
            height: { xs: '8rem', md: '13rem' },
            justifyContent: 'center',
            flexDirection: 'column',
            mx: 'auto',
            p: 2
        }}
    >
        <Typography variant="h3" className='fontBeta' sx={{ color: theme.palette.text.white }}>
            {inView ? <CountUp end={end} duration={3} /> : '0'}
            {end !== 0 && '+'}
        </Typography>
        <Typography variant="h6" className='fontAlpha' sx={{ color: theme.palette.text.white }}>
            {label}
        </Typography>
    </Box>
    );
};

const OrderCounts = ({ title }) => {
  const navigate = useNavigate();

  return (
      <Box sx={{ px: { xs: 3, sm: 6, md: 12 }, minHeight: {md:"100vh", xs:"100vh"}, background: theme.palette.background.black, display: 'flex', justifyContent: 'center', flexDirection: 'column', py:5 }}>
          <Grid container>
              <Grid 
                  item 
                  md={6} 
                  sm={12} 
                  xs={12} 
                  data-aos="fade-up" 
                  data-aos-once={true}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                  <Typography 
                      variant="h4" 
                      className='fontGama' 
                      sx={{ 
                          ml: { xs: 0, md: 13 }, 
                          color: theme.palette.text.white, 
                          textAlign: { xs: 'center', md: 'left' }, 
                          fontWeight: 400 
                      }}
                  >
                      Bulk/Corporate Orders
                  </Typography>
                  
                  <Box 
                      sx={{ 
                          display: 'flex', 
                          justifyContent: { xs: 'center', md: 'left' }, 
                          flexDirection: { xs: 'column', md: 'row' }, 
                          alignItems: 'center', 
                          mt: 3
                      }}
                  >
                      <Divider 
                          sx={{ 
                              width: '5rem', 
                              background: theme.palette.background.default, 
                              height: '1px', 
                              mt: 3, 
                              mr: { xs: 0, md: 4 }, 
                              mb: { xs: 2, md: 0 } 
                          }} 
                      />
                      
                      <Box>
                          <Typography 
                              variant="h1" 
                              sx={{ 
                                  color: theme.palette.text.default, 
                                  width: '100%', 
                                  textAlign: { xs: 'center', md: 'left' } 
                              }}
                          >
                              {title}
                          </Typography>
                          
                          <Box 
                              sx={{ 
                                  mt: 3, 
                                  display: 'flex', 
                                  justifyContent: { xs: 'center', md: 'flex-start' }, 
                                  flexWrap: 'wrap', 
                                  alignItems: 'center' 
                              }}
                          >
                              <Button 
                                  variant="filled" 
                                  className='fontBeta' 
                                  sx={{ mb: { xs: 2, md: 0 } }} 
                                  onClick={() => { navigate("/meal-service") }}
                              >
                                  <Typography 
                                      variant="h5" 
                                      className='fontBeta' 
                                      sx={{ color: theme.palette.text.black }}
                                  >
                                      Order now
                                  </Typography>
                              </Button>

                              <Button 
                                  variant="outlined" 
                                  className='fontBeta' 
                                  sx={{
                                      borderColor: "#fff",
                                      ml: { xs: 2, md: 2 },
                                      mb: { xs: 2, md: 0 },
                                      fontWeight: 400,
                                      '&:hover': {
                                          borderColor: '#fff',
                                          backgroundColor: 'transparent',
                                          color: '#fff',
                                      },
                                  }}
                                  onClick={() => { navigate("/meal-service") }}
                              >
                                  <Typography 
                                      variant="h5" 
                                      className='fontBeta' 
                                      sx={{ color: theme.palette.text.white, fontWeight: 400 }}
                                  >
                                      Order now
                                  </Typography>
                              </Button>
                          </Box>
                      </Box>
                  </Box>
              </Grid>

              <Grid item md={6} sm={12} xs={12} data-aos="zoom-in" data-aos-once={true} sx={{mt:{xs:5}}}>
                  <Grid container justifyContent="center" spacing={4}>
                      <Grid item xs={6} sm={6} md={6}>
                          <CounterBox end={15} label="Years of Experience" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                          <CounterBox end={200000} label="Meals Delivered" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                          <CounterBox end={2000} label="Clients" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                          <CounterBox end={250} label="Events Catered For" />
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </Box>
    );
};

export default OrderCounts;
