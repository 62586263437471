import React, { useEffect } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import Banner from '../../Components/Banner.js';
import Brief from './Component/Brief.js';
import Discription from '../AboutUs/Component/Discription.js';
import HostForm from '../../Components/HostForm.js';
import CAteringServiceMain from '../../Images/CateringServiceBanner.jpg'
import Catering1 from '../../Images/Catering1.jpg'
import Catering2 from '../../Images/Catering2.jpg'

const CateringService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <Box >
        <Navbar/>

            <Banner title={"Catering Service"} image={CAteringServiceMain} />
            <Brief
                brief={"We specialise in vegetarian catering services across Hyderabad. One of the best catering services in Hyderabad, we have been successfully extending our catering services to hospitals, corporates and corporate events, schools, and wedding and pre-wedding events. One of the best catering services in Hyderabad, our team of caterers is equipped to serve a big gathering with just as ease as a small social gathering. With options from varied cuisines and customisations we can arrange a buffet spread for 50 - 1000 pax well within your budget, without any compromise on quality & service. Choose our catering services for your event in Hyderabad, because the mouth-watering dishes by our highly experienced chefs will surely make your guest go home in a happy mood and not complain about the food! We are the best in catering services for vegetarians in Hyderabad. We can cater to anyone, anywhere with our range of dishes from chaat stalls to fine dining! We have catering services that will suit your every need. From small social gatherings to large celebrations, our team of highly trained chefs are ready for anything with options ranging from diverse cuisines and customised menus so you don't have worry about what's on the menu! We provide the best catering services in Hyderabad for an event that will be sure to please your guest. Choose our mouth-watering dishes by highly experienced chefs and they'll go home happy!"}
            />
            <Discription title={"Wedding Catering"} image1={Catering1} image2={Catering2}
            description={"Make your wedding remarkable and special with our amazing catering services. Let us help you plan the perfect menu for all of those big day festivities! From homemade appetizers to dessert, we have it covered at Home's Kitchens Wedding Caterers- guaranteed not only delicious but also 100% vegetarian food. Our expert chefs will take care of every detail so that even guests with dietary restrictions can enjoy themselves during this most joyous event of a wedding."}
            />
            <HostForm />
        </Box >
    );
};

export default CateringService;
