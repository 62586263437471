import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import Banner from '../../Components/Banner.js';
import Discription from './Component/Discription.js';
import RegistrationModal from '../../Components/RegisterationModal.js';
import LoginModal from '../../Components/LoginModal.js';
import About1 from '../../Images/About1.jpg'
import About2 from '../../Images/About2.jpg'
import AboutMain from '../../Images/AboutBanner.jpg'

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    //   const [open, setOpen] = useState(true);

    //   const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <Box >
        <Navbar/>

            <Banner title={"About us"} image={AboutMain} />
            <Discription
                title={"Home's Kitchen"}
                image1={About1}
                image2={About2}
                description={"Home’s Kitchen is a unit of Theme Ambience Resorts & Hotels Pvt. Ltd., a company which is into the hospitality and food business in Hyderabad since the past 15 years. We provide vegetarian homely food packed in meal combos at reasonable prices. Our North Indian & South Indian meal combos with homely curries, dal, rotis, rice, etc are hot favourites in the market. The delicious, nutritious and ready-to-go vegetarian meals would be delivered right at your door-step. Home’s Kitchen also provides corporate cetering on a regular basis and alsovegetarian catering services for various events. The food is freshly cooked by our team of chefs with utmost care and the best of ingredients. We also have a wide variety of sweets, snacks and namkeen to treat your tongue on special occasions."}
            />
            {/* <RegistrationModal open={open} handleClose={handleClose}/>     */}
            {/* <LoginModal open={open} handleClose={handleClose}/>     */}
        </Box >
    );
};

export default AboutUs;
