import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Container, TextareaAutosize, Snackbar, Alert } from '@mui/material';
import CustomLoader from './CustomLoader';
import { enquiry } from '../api/api/action';
import { useDispatch } from 'react-redux';

const EnquiryForm = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [email, setEmail] = useState('');
    const [requirement, setRequirement] = useState('');
    const [comment, setComment] = useState('');

    const [nameError, setNameError] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [requirementError, setRequirementError] = useState('');
    const [commentError, setCommentError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validate()) {
            setSnackbarMessage("Please fill all fields correctly");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            "name": name,
            "email": email,
            "phone": contactNumber,
            "subject": requirement,
            "description": comment
        };

        setIsLoader(true);

        dispatch(
            enquiry(payload,
                (data) => {
                    setIsLoader(false);
                    console.log(data);
                    setSnackbarMessage("Thank you! Your enquiry has been submitted.");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    // navigate("/cart")
                },
                (err) => {
                    setIsLoader(false);
                    console.log(err);
                    setSnackbarMessage(err?.response?.data?.msg);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const validate = () => {
        let isValid = true;

        if (!name) {
            setNameError('Name is required');
            isValid = false;
        } else {
            setNameError('');
        }

        if (!company) {
            setCompanyError('Company is required');
            isValid = false;
        } else {
            setCompanyError('');
        }

        const contactNumberPattern = /^[0-9]{10}$/;
        if (!contactNumber) {
            setContactNumberError('Contact number is required');
            isValid = false;
        } else if (!contactNumberPattern.test(contactNumber)) {
            setContactNumberError('Contact number is invalid');
            isValid = false;
        } else {
            setContactNumberError('');
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setEmailError('Email is required');
            isValid = false;
        } else if (!emailPattern.test(email)) {
            setEmailError('Email is invalid');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!requirement) {
            setRequirementError('Requirement is required');
            isValid = false;
        } else {
            setRequirementError('');
        }

        if (!comment) {
            setCommentError('Comment is required');
            isValid = false;
        } else {
            setCommentError('');
        }

        return isValid;
    };


    useEffect(() => {
        if (isSubmitting) {
            // Handle form submission logic here
            console.log('Form values: ', { name, company, contactNumber, email, requirement, comment });
            setIsSubmitting(false);
        }
    }, [isSubmitting, name, company, contactNumber, email, requirement, comment]);

    return (
        <Container maxWidth="sm" >

            <CustomLoader open={isLoader} setOpen={setIsLoader} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    bgcolor: 'background.paper',
                    p: 4,
                    borderRadius: 3,
                    boxShadow: 3,
                    mb: 5,
                    mt: 5, boxShadow:'none'
                }}
            >
                <Typography variant="h1" sx={{ mb: 2 }}>
                    Enquiry Form
                </Typography>
                <TextField
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                />
                <TextField
                    placeholder="Company"
                    variant="outlined"
                    fullWidth
                    required
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    error={!!companyError}
                    helperText={companyError}
                />
                <TextField
                    placeholder="Contact Number"
                    variant="outlined"
                    fullWidth
                    required
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    error={!!contactNumberError}
                    helperText={contactNumberError}
                />
                <TextField
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                    type="email"
                />
                <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Your Requirement
                    </Typography>
                    <TextareaAutosize
                        minRows={4}
                        // placeholder="Requirement"
                        style={{
                            width: '95%',
                            padding: '16.5px 14px',
                            borderRadius: '4px',
                            border: '1px solid rgba(0, 0, 0, 0.23)'
                        }}
                        value={requirement}
                        onChange={(e) => setRequirement(e.target.value)}
                    />
                    {requirementError && (
                        <Typography variant="caption" color="error">
                            {requirementError}
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Comment
                    </Typography>
                    <TextareaAutosize
                        minRows={4}
                        // placeholder="Comment"
                        style={{
                            width: '95%',
                            padding: '16.5px 14px',
                            borderRadius: '4px',
                            border: '1px solid rgba(0, 0, 0, 0.23)'
                        }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    {commentError && (
                        <Typography variant="caption" color="error">
                            {commentError}
                        </Typography>
                    )}
                </Box>
                <Button variant="contained" color="primary" type="submit" fullWidth sx={{ boxShadow: 'none !important' }}>
                    Submit
                </Button>
            </Box>
        </Container>
    );
};

export default EnquiryForm;
