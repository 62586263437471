import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../../theme.js';
import mealImage from '../../../Images/mealImage.jpg';

const Discription = ({ title, description, image1, image2 }) => {

    const ImageContainer = styled(Box)({
        position: 'relative',
        width: 'fit-content',
        margin: 'auto',
    });

    const LargeImage = styled('img')(({ theme }) => ({
        width: '20rem', // Adjust size as needed
        height: '20rem',
        borderRadius: '8px',
        objectFit: 'cover',
        [theme.breakpoints.down('lg')]: {
            width: '18rem',
            height: '18rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '14rem',
            height: '14rem',
        },
    }));

    const SmallImage = styled('img')(({ theme }) => ({
        width: '10rem', // Adjust size as needed
        height: '10rem',
        position: 'absolute',
        bottom: '-100px',
        left: '-100px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        objectFit: 'cover',
        [theme.breakpoints.down('lg')]: {
            width: '8rem',
            height: '8rem',
            bottom: '-80px',
            left: '-80px',
        },
        [theme.breakpoints.down('md')]: {
            width: '6rem',
            height: '6rem',
            bottom: '-60px',
            left: '-60px',
        },
    }));

    return (
        <Box sx={{ px: { xs: 3, sm: 6, md: 4 }, minHeight: "100vh", background: theme.palette.background.black, display: 'flex', justifyContent: 'center', alignItems: 'center', py:{md:4, sm:5, xs:5}, pb:{xs:15} }}>
            <Grid container spacing={5}>
                <Grid item md={6} sm={12} xs={12} data-aos="fade-up" data-aos-once={true}>
                    <Typography variant="body1" sx={{ ml: { xs: 0, md: 13 }, color: theme.palette.text.white, textAlign: { xs: 'center', md: 'left' } }}>
                        About us
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                        <Divider sx={{ width: '5rem', background: theme.palette.background.default, height: '1px', mt: 3, mr: { xs: 0, md: 4 }, mb: { xs: 2, md: 0 } }} />
                        <Box>
                            <Typography variant="h1" sx={{ color: theme.palette.text.default, width: { xs: '100%', md: '20rem' }, textAlign: { xs: 'center', md: 'left', lg:'center' } }}>
                                {title}
                            </Typography>
                            <Typography variant="h6" className='fontBeta' sx={{ color: theme.palette.text.white, width: { xs: '100%', md: '100%' }, textAlign: { xs: 'left', md: 'left' }, mt: 2, fontWeight: 400 }}>
                                {description}
                            </Typography>
                            {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, flexWrap: 'wrap' }}>
                                <Button variant="filled" className='fontBeta' sx={{ mb: { xs: 2, md: 0 } }}>
                                    <Typography variant="body2" className='fontBeta'>
                                        Order now
                                    </Typography>
                                </Button>

                                <Button variant="outlined" className='fontBeta' sx={{
                                    borderColor: "#fff",
                                    ml: { xs: 2, md: 2 },
                                    mb: { xs: 2, md: 0 },
                                    '&:hover': {
                                        borderColor: '#fff',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                    },
                                }}
                                >
                                    <Typography variant="body2" className='fontBeta' sx={{ color: theme.palette.text.white }}>
                                        Order now
                                    </Typography>
                                </Button>
                            </Box> */}
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12} data-aos="zoom-in" data-aos-once={true}>
                    <ImageContainer>
                        <LargeImage src={image1} alt="Large Food" />
                        <SmallImage src={image2} alt="Small Food" />
                    </ImageContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Discription;
