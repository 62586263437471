import React, { useEffect, useRef } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import PlateFrame from '../../../Images/PlateFrame.svg';
import Plate from '../../../Images/Plate.svg';
import DownArrow from '../../../Images/DownArrow.svg';
import leaf from '../../../Images/leaf.svg';
import fullLeaf from '../../../Images/Background2.svg';

const Template = () => {
    const nextComponentRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isTab = useMediaQuery(theme.breakpoints.down('sm'));

    const handleScroll = () => {
        window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        const keyframes = `
            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        `;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '90vh',
                px: { xs: 5 },
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            {(isMobile || isTab) && (
                <Box sx={{ mt: 2 }}>
                    <img
                        src={leaf}
                        alt="Leaf"
                        style={{
                            position: 'absolute',
                            width: isMobile ? '60%' : '50%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            top: isMobile ? '-18px' : '-5%',
                            right: isMobile ? '-107px' : '-22%',
                        }}
                        // data-aos="zoom-in"
                    />
                </Box>
            )}

            <Box sx={{ zIndex: 1, mt: 4 }} data-aos="fade-up" data-aos-once>
                <Typography variant="h1" sx={{ fontSize: { md: '48px', xs: '32px', sm: "40px", }, textAlign: 'center', fontWeight: { xs: 600, md: 600 } }}>
                    Taste, Hygiene And <br /> Nutrition Reloaded
                </Typography>
                <Typography variant={isMobile ? 'body1' : 'h5'} sx={{ textAlign: 'center', mt: 2 }}>
                    Home-styled meals delivered to your doorstep
                </Typography>
            </Box>

            {(isMobile || isTab) && (
                <Box sx={{ mt: 2 }}>
                    <img
                        src={fullLeaf}
                        alt="Full Leaf"
                        style={{
                            position: 'absolute',
                            width: isMobile ? '60%' : '50%',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            bottom: isMobile ? '118px' : '0%',
                            left: isMobile ? '-118px' : '-25%',
                        }}
                        // data-aos="zoom-in"
                    />
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: { md: 3, xs: 5 },
                    position: { md: 'absolute', xs: 'relative' },
                    left: { md: '48%', xs: 'auto' },
                    top: '75%'
                }}
                data-aos="fade-up"
                data-aos-once
            >
                <IconButton disableRipple onClick={handleScroll}>
                    <img
                        src={DownArrow}
                        alt="Down Arrow"
                        style={{
                            height: isMobile ? '75px' : '100px',
                            position: isMobile ? 'absolute' : 'absolute',
                            bottom: isMobile ? '-110px' : '-50px'
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Template;
