import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { addToCart } from "../api/api/action";
import CustomLoader from "./CustomLoader";
import { useDispatch } from 'react-redux';


const MealViewModal = ({ open, handleClose, item }) => {
  const userId = localStorage.getItem("user_id")
  const [mealType, setMealType] = useState("");
  const [mealPlan, setMealPlan] = useState("");
  const [mealQuantity, setMealQuantity] = useState(1);
  const [gulabJamoon, setGulabJamoon] = useState(0);
  const [moongDalHalwa, setMoongDalHalwa] = useState(0);
  const [todaysSpecialSweet, setTodaysSpecialSweet] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [foodOrderId, setFoodOrderId] = useState([]);
  const handleMealTypeChange = (event) => setMealType(event.target.value);
  const handleMealPlanChange = (event) => setMealPlan(event.target.value);
  const handleMealQuantityChange = (event) => setMealQuantity(event.target.value);
  const handleGulabJamoonChange = (event) => setGulabJamoon(event.target.value);
  const handleMoongDalHalwaChange = (event) => setMoongDalHalwa(event.target.value);
  const handleTodaysSpecialSweetChange = (event) => setTodaysSpecialSweet(event.target.value);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const foodAddOns = {
    "Gulab Jamoon": gulabJamoon,
    "Moong Dal Halwa": moongDalHalwa,
    "Today's Special Sweet": todaysSpecialSweet,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if ( localStorage.getItem("accessToken")) {
    //   const errorMessage = 'Please Login';
    //   setSnackbarMessage(errorMessage);
    //   setSnackbarSeverity("error");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // Validate all fields
    const payload = {
      "user": userId,
      "food_category": mealType,
      "meal_type": mealType,
      "meal_quantity": mealQuantity,
      "meal_plan": mealPlan,
      "food_price": item?.food_price,
      "food_add_ons": foodAddOns,
      "food_description": item.description,
      "food_name": item?.food_name,
      "food_data": item?.id,

    }

    setIsLoader(true)

    dispatch(
      addToCart(payload,
        (data) => {
          setIsLoader(false)
          console.log(data)
          const storedFoodOrderId = JSON.parse(localStorage.getItem("foodOrderId")) || [];

          const newFoodOrderId = data?.data?.food_order_id;
          if (newFoodOrderId) {
            const updatedFoodOrderId = [...storedFoodOrderId, newFoodOrderId];
            setFoodOrderId(updatedFoodOrderId);
            localStorage.setItem("foodOrderId", JSON.stringify(updatedFoodOrderId));
          }
          setSnackbarMessage("Order added to cart");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          navigate("/cart")
        },
        (err) => {
          setIsLoader(false)
          console.log(err)
          setSnackbarMessage(err?.response?.data?.detail === "Given token not valid for any token type" ? "Please Login Again" : err?.response?.data?.msg);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
    // Dummy submission logic (replace with actual logic)

  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <DialogTitle>
        <Typography sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose}>
          <HighlightOff />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box m={5}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Box
                component={"img"}
                src={`data:image/png;base64,${item?.food_image}`}
                sx={{
                  maxWidth: "100%", height: "100%",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
                alt="Food Image"
              />
            </Grid>
            <Grid item md={12}>
              <Box my={2}>
                <Typography variant="h4">Rs. {item.food_price}</Typography>

                {/* <Typography variant="h6">{item.Name}</Typography> */}
                <StarRatings
                  rating={3.7}
                  starRatedColor="#fcb941"
                  numberOfStars={5}
                  name="rating"
                  starDimension={"15px"}
                  starSpacing={"0px"}
                />
              </Box>

              <Typography variant="h5">{item.food_name}</Typography>
              <Typography variant="body2">
                {item.food_description}
              </Typography>

              <form onSubmit={handleSubmit}>
                <Typography variant="body2" fontWeight={"bold"} sx={{ mt: 2 }}>
                  MEAL TYPE
                </Typography>
                <Select
                  value={mealType}
                  onChange={handleMealTypeChange}
                  required
                  fullWidth
                  displayEmpty
                  sx={{ mt: 1, mb: 2 }}
                  inputProps={{
                    sx: { height: '2.5rem' },
                  }}
                >
                  <MenuItem value="">
                    <em>Choose an Option</em>
                  </MenuItem>
                  <MenuItem value="Lunch">Lunch</MenuItem>
                  <MenuItem value="Dinner">Dinner</MenuItem>
                </Select>
                <Typography variant="body2" fontWeight={"bold"} sx={{ mt: 2 }}>
                  MEAL PLAN
                </Typography>
                <Select
                  value={mealPlan}
                  onChange={handleMealPlanChange}
                  required
                  fullWidth
                  displayEmpty
                  sx={{ mt: 1, mb: 2 }}
                  inputProps={{
                    sx: { height: '2.5rem' },
                  }}
                >
                  <MenuItem value="">
                    <em>Choose an Option</em>
                  </MenuItem>
                  <MenuItem value="1">Single Day</MenuItem>
                  <MenuItem value="5">5 Days a Week</MenuItem>
                  <MenuItem value="6">6 Days a Week</MenuItem>
                  <MenuItem value="7">7 Days a Week</MenuItem>
                  <MenuItem value="5_monthly">5 Days a Week Monthly</MenuItem>
                  <MenuItem value="6_monthly">6 Days a Week Monthly</MenuItem>
                  <MenuItem value="7_monthly">7 Days a Week Monthly</MenuItem>
                </Select>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Meal Quantity
                </Typography>
                <TextField
                  type="number"
                  value={mealQuantity}
                  onChange={handleMealQuantityChange}
                  required
                  fullWidth
                  inputProps={{ min: 1 }}
                  sx={{ mt: 1, mb: 2 }}
                  InputProps={{
                    sx: { height: '2.5rem' },
                  }}
                />
                <Typography variant="h6" mt={3}>Add-ons</Typography>
                <Typography variant="body2" mt={2} fontWeight={"bold"}>GULAB JAMOON</Typography>
                <TextField
                  type="number"
                  value={gulabJamoon}
                  onChange={handleGulabJamoonChange}
                  fullWidth
                  inputProps={{ min: 1 }}
                  sx={{ mt: 1, mb: 2 }}
                  InputProps={{
                    sx: { height: '2.5rem' },
                  }}
                />
                <Typography variant="body2" mt={2} fontWeight={"bold"}>MOONG DAL HALWA</Typography>
                <TextField
                  type="number"
                  value={moongDalHalwa}
                  onChange={handleMoongDalHalwaChange}
                  fullWidth
                  inputProps={{ min: 1 }}
                  sx={{ mt: 1, mb: 2 }}
                  InputProps={{
                    sx: { height: '2.5rem' },
                  }}
                />
                <Typography variant="body2" mt={2} fontWeight={"bold"}>TODAY'S SPECIAL SWEET</Typography>
                <TextField
                  type="number"
                  value={todaysSpecialSweet}
                  onChange={handleTodaysSpecialSweetChange}
                  fullWidth
                  inputProps={{ min: 1 }}
                  sx={{ mt: 1, mb: 2 }}
                  InputProps={{
                    sx: { height: '2.5rem' },
                  }}
                />
                <Button type="submit" variant="contained" sx={{ mt: 3, boxShadow: 'none !important' }} onClick={handleSubmit}>Add To Cart</Button>
              </form>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <CustomLoader open={isLoader} setOpen={setIsLoader} />

    </Dialog>
  );
};

export default MealViewModal;
