import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import CustomLoader from './CustomLoader';
import { enquiry, host } from '../api/api/action';
import theme from '../theme';

const HostForm = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [date, setDate] = useState('');
    const [numberOfPeople, setNumberOfPeople] = useState('');

    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cityError, setCityError] = useState('');
    const [dateError, setDateError] = useState('');
    const [numberOfPeopleError, setNumberOfPeopleError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const dispatch = useDispatch();

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const validate = () => {
        let isValid = true;

        if (!name) {
            setNameError('Name is required');
            isValid = false;
        } else {
            setNameError('');
        }

        const phoneNumberPattern = /^[0-9]{10}$/;
        if (!phoneNumber) {
            setPhoneNumberError('Phone number is required');
            isValid = false;
        } else if (!phoneNumberPattern.test(phoneNumber)) {
            setPhoneNumberError('Phone number is invalid');
            isValid = false;
        } else {
            setPhoneNumberError('');
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setEmailError('Email is required');
            isValid = false;
        } else if (!emailPattern.test(email)) {
            setEmailError('Email is invalid');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!city) {
            setCityError('City is required');
            isValid = false;
        } else {
            setCityError('');
        }

        if (!date) {
            setDateError('Date is required');
            isValid = false;
        } else {
            setDateError('');
        }

        if (!numberOfPeople) {
            setNumberOfPeopleError('Number of people is required');
            isValid = false;
        } else if (numberOfPeople <= 0) {
            setNumberOfPeopleError('Number of people must be greater than zero');
            isValid = false;
        } else {
            setNumberOfPeopleError('');
        }

        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validate()) {
            setSnackbarMessage("Please fill all fields correctly");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            "email": email,
            "phone": phoneNumber,
            "name": name,
            "number_of_people": numberOfPeople,
            "city": city,
            "date": date
        };

        setIsLoader(true);

        dispatch(
            host(payload,
                (data) => {
                    setIsLoader(false);
                    console.log(data);
                    setSnackbarMessage("Thank you! We will contact you soon.");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setEmail("")
                    setPhoneNumber("")
                    setName("")
                    setNumberOfPeople("")
                    setDate("")
                    setCity("")
                    // navigate("/cart")
                },
                (err) => {
                    setIsLoader(false);
                    console.log(err);
                    setSnackbarMessage(err?.response?.data?.msg);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };


    useEffect(() => {
        if (isSubmitting) {
            // Handle form submission logic here
            console.log('Form values: ', { name, phoneNumber, email, city, date, numberOfPeople });
            setIsSubmitting(false);
        }
    }, [isSubmitting, name, phoneNumber, email, city, date, numberOfPeople]);

    return (
        <Container maxWidth="sm">
            <CustomLoader open={isLoader} setOpen={setIsLoader} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    bgcolor: 'background.paper',
                    p: 4,
                    borderRadius: 3,
                    boxShadow: 3,
                    mb: 5,
                    mt: 5, boxShadow:'none',
                }}
            >
                <Typography variant="h1" sx={{ mb: 2 }}>
                    You Host, We Serve
                </Typography>
                <TextField
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                />
                <TextField
                    placeholder="Phone Number"
                    variant="outlined"
                    fullWidth
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={!!phoneNumberError}
                    helperText={phoneNumberError}
                />
                <TextField
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                    type="email"
                />
                <TextField
                    placeholder="City"
                    variant="outlined"
                    fullWidth
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    error={!!cityError}
                    helperText={cityError}
                />
                <TextField
                    placeholder="Date"
                    variant="outlined"
                    fullWidth
                    required
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    error={!!dateError}
                    helperText={dateError}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    placeholder="Number of People"
                    variant="outlined"
                    fullWidth
                    required
                    value={numberOfPeople}
                    onChange={(e) => setNumberOfPeople(e.target.value)}
                    error={!!numberOfPeopleError}
                    helperText={numberOfPeopleError}
                    type="number"
                />
                <Button variant="contained" color="primary" type="submit" fullWidth elevation={0} sx={{ boxShadow: 'none !important' }}>
                    Submit
                </Button>
            </Box>
        </Container>
    );
};

export default HostForm;
