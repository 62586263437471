import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Paper, InputLabel } from '@mui/material';
import theme from '../../theme';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleLogin = () => {
    let valid = true;

    if (!email) {
      setEmailError('Email is required');
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Email is invalid');
      valid = false;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required');
      valid = false;
    } else if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      valid = false;
    } else {
      setPasswordError('');
    }

    if (valid) {
      console.log('Logging in with:', { email, password });
      // Add your login logic here
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(https://source.unsplash.com/1600x900/?food,restaurant)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={6}
          style={{
            padding: theme.spacing(4),
            borderRadius: '12px',
            maxWidth: '400px',
            width: '100%',
            boxShadow: theme.shadows[10],
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h4"
            style={{
              marginBottom: theme.spacing(3),
              fontWeight: 'bold',
            }}
          >
            Welcome Back!
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <InputLabel
              htmlFor="email"
              sx={{
                display: 'block',
                textAlign: 'left',
                marginBottom: theme.spacing(1),
                fontSize: '1rem',
                color: theme.palette.text.lightBlack,
                fontWeight: 500,
              }}
            >
              Email Address
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{
                py:0, my:0
              }}
            />
            <InputLabel
              htmlFor="password"
              sx={{
                display: 'block',
                textAlign: 'left',
                marginBottom: theme.spacing(1),
                fontSize: '1rem',
                color: theme.palette.text.lightBlack,
                fontWeight: 500,
                mt:2
              }}
            >
              Password
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!passwordError}
              helperText={passwordError}
              sx={{
                py:0, my:0,
              }}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={handleLogin}
              style={{
                borderRadius: '30px',
                textTransform: 'none',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.white,
                padding: theme.spacing(1.5, 0),
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(2),
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default AdminLogin;
