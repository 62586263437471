import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFCC00', // Primary color
        },
        secondary: {
            main: '#000000', // Secondary color
        },
        error: {
            main: '#f44336', // Error color
        },
        warning: {
            main: '#ff9800', // Warning color
        },
        info: {
            main: '#2196f3', // Info color
        },
        success: {
            main: '#4caf50', // Success color
        },
        background: {
            default: '#FFCC00',
            white: '#ffffff',
            black: '#000',
            brown: '#AF431A',
        },
        text: {
            black: '#000000',
            brown: '#AF431A',
            lightBlack: '#45474B',
            white: '#fff',
            default: '#FFCC00',
            disabled: '#9e9e9e',
            hint: '#9e9e9e', // Hint text color
        },
    },
    typography: {
        fontFamily: '"Marcellus", "Nunito", "Be Vietnam Pro", serif',
        h1: {
            fontFamily: '"Marcellus", serif',
            fontSize: '2.5rem', // Adjusted font size for responsiveness
            fontWeight: 400,
            lineHeight: 1.2,
        },
        h2: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '2rem', // Adjusted font size for responsiveness
            fontWeight: 700,
            lineHeight: 1.3,
        },
        h3: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '1.75rem', // Adjusted font size for responsiveness
            fontWeight: 700,
            lineHeight: 1.4,
        },
        h4: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '1.5rem', // Adjusted font size for responsiveness
            fontWeight: 700,
            lineHeight: 1.5,
        },
        h5: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '1.25rem', // Adjusted font size for responsiveness
            fontWeight: 700,
            lineHeight: 1.6,
        },
        h6: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '1rem', // Adjusted font size for responsiveness
            fontWeight: 700,
            lineHeight: 1.7,
        },
        subtitle1: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '1rem', // Adjusted font size for responsiveness
            fontWeight: 500,
            lineHeight: 1.75,
        },
        subtitle2: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '0.875rem', // Adjusted font size for responsiveness
            fontWeight: 500,
            lineHeight: 1.57,
        },
        body1: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '1rem', // Adjusted font size for responsiveness
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '0.875rem', // Adjusted font size for responsiveness
            fontWeight: 400,
            lineHeight: 1.43,
        },
        button: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '0.875rem', // Adjusted font size for responsiveness
            fontWeight: 500,
            lineHeight: 1.75,
            textTransform: 'none',
        },
        caption: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '0.75rem', // Adjusted font size for responsiveness
            fontWeight: 400,
            lineHeight: 1.66,
        },
        overline: {
            fontFamily: '"Be Vietnam Pro", sans-serif',
            fontSize: '0.75rem', // Adjusted font size for responsiveness
            fontWeight: 400,
            lineHeight: 2.66,
            textTransform: 'uppercase',
        },
    },

    breakpoints: {
        values: {
            xs: 300,    // Extra small
            sm: 767,  // Small
            md: 991,  // Medium
            lg: 1199, // Large
            xl: 1399, // Extra large
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    boxShadow: 'none',
                    // '&:hover': {
                    //     borderColor: '#AF431A',
                    //     backgroundColor: '#AF431A',
                    //     color: '#fff',
                    // },
                },
                outlined: {
                    borderColor: '#45474B',
                    color: '#45474B',
                    borderRadius: '0.5rem',
                    textTransform: 'none !important',
                    '&:hover': {
                        borderColor: '#45474B',
                        backgroundColor: '#fff',
                        color: '#45474B',
                    },
                },
                filled: {
                    borderColor: '#FFCC00',
                    color: '#fff',
                    borderRadius: '0.5rem',
                    backgroundColor: '#FFCC00',
                    textTransform: 'none !important',
                    '&:hover': {
                        borderColor: '#FFCC00',
                        backgroundColor: '#FFCC00',
                        color: '#fff',
                    },
                },
                customVariant: {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#333333',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'customVariant' },
                    style: {
                        backgroundColor: '#000000',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#333333',
                        },
                    },
                },
            ],
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiOutlinedInput-root': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiInputBase-input': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiOutlinedInput-input': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: '2.5rem',
                    fontSize: '0.75rem',
                    '& .MuiInputBase-root': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiOutlinedInput-root': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiInputBase-input': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                    '& .MuiOutlinedInput-input': {
                        height: '2.5rem',
                        fontSize: '0.75rem'
                    },
                },
            },
        },
    },
});

export default theme;
