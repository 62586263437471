export const endpoints = {
  REGISTERATION: '/kitchen/register/',
  LOGIN: '/kitchen/login/',
  VERIFY_OTP: '/kitchen/verify-otp/',

  GET_ALL_FOOD_ITEMS: '/kitchen/fooddata/',
  CART: '/kitchen/food-carts/',


  ADD_TO_CART:'/kitchen/food-orders/',
  DELIVERY:'/kitchen/delivery/',

  ENQUIRY:'/kitchen/enquiry/',
  HOST:'/kitchen/enquiries/',
  PAYMENT:'/kitchen/pay/',

  GETCART:'/kitchen/food-orders/postdetails/',

};