import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import mealImage from '../../../Images/mealImage.jpg';
import theme from '../../../theme.js';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Right = ({ title, qa, image1, image2 }) => {
    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const ImageContainer = styled(Box)({
        position: 'relative',
        width: 'fit-content',
        margin: 'auto',
    });

    const LargeImage = styled('img')(({ theme }) => ({
        width: '25rem', // Adjust size as needed
        height: '25rem',
        borderRadius: '8px',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            width: '18rem',
            height: '18rem',
        },
        [theme.breakpoints.down('sm')]: {
            width: '14rem',
            height: '14rem',
        },
    }));

    const SmallImage = styled('img')(({ theme }) => ({
        width: '10rem', // Adjust size as needed
        height: '10rem',
        position: 'absolute',
        bottom: '-100px',
        left: '-100px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            width: '8rem',
            height: '8rem',
            bottom: '-80px',
            left: '-80px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '6rem',
            height: '6rem',
            bottom: '-60px',
            left: '-60px',
        },
    }));

    return (
        <Box sx={{ px: { xs: 2, md: 12 }, minHeight: { md: "100vh", xs: "100vh" }, display: 'flex', justifyContent: 'center', flexDirection: 'column', py: 5 }}>
            <Grid container spacing={5}>
                <Grid item md={6} sm={12} xs={12}>
                    <Typography variant="body1" sx={{ ml: { xs: 0, md: 13 }, color: theme.palette.text.black, textAlign: { xs: 'center', md: 'left' } }}>
                        About
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                        <Divider sx={{ width: '5rem', background: theme.palette.background.default, height: '2px', mt: 3, mr: { xs: 0, md: 4 }, mb: { xs: 2, md: 0 } }} />
                        <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                            <Typography variant="h1" sx={{ color: theme.palette.text.black, width: { xs: "auto", md: "20rem" }, mb: 2 }}>
                                {title}
                            </Typography>
                            {qa.map((item, index) => (
                                <Accordion key={index}
                                    expanded={expanded === index}
                                    onChange={handleChange(index)}
                                    sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.black }} />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography variant="h6" className='fontAlpha' sx={{ color: theme.palette.text.black, width: { xs: "auto", md: "100%" }, fontWeight: 600 }}>
                                            {item.question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="h6" sx={{ color: theme.palette.text.black, width: { xs: "auto", md: "100%" }, mb: 2, fontWeight: 400 }}>
                                            {item.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12} xs={12} sx={{py:{xs:10}}}>
                    <ImageContainer>
                        <LargeImage src={image1} alt="Large Food" />
                        <SmallImage src={image2} alt="Small Food" />
                    </ImageContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Right;
