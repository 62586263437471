import { Autorenew } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import CustomLoader from "../../Components/CustomLoader";
import { deleteFromCart, getCart } from "../../api/api/action";
import Checkout from "../Checkout/Checkout";
import DeleteIcon from '@mui/icons-material/Delete';
import Navbar from "../../Components/Navbar";

const Cart = () => {
  const userId = localStorage.getItem("user_id") || null;
  const foodOrderId = localStorage.getItem("foodOrderIds")
  console.log(foodOrderId, "foodOrderIds")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [cartItem, setCartItem] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [total, setTotal] = useState("");
  const [gst, setGst] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [updated, setUpdated] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDelete = (id) => {
    const payload = null;
    setIsLoader(true);
    dispatch(
      deleteFromCart(payload, id,
        (data) => {
          setIsLoader(false);
          setSnackbarMessage("Your item deleted successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setUpdated(prevState => !prevState);
        },
        (err) => {
          setIsLoader(false);
          console.log(err);
        }
      )
    );
  };

  function arrayToCommaSeparatedString(arr) {
    // Ensure arr is an array
    if (!Array.isArray(arr)) {
      // If it's a string, split by commas or handle it as needed
      if (typeof arr === 'string') {
        arr = arr.split(',').map(item => item.trim());
      } else {
        // Default to an empty array if it's neither an array nor a string
        arr = [];
      }
    }
    return arr.join(', ');
  }

  useEffect(() => {
    const payload = {
      // "user_id": userId === "" ? null : userId,
      "food_order_id": foodOrderId
    };
   
    setIsLoader(true);
    dispatch(
      getCart(payload,
        (data) => {
          setIsLoader(false);
          console.log(data?.data)
          setCartItem(data?.data);
        },
        (err) => {
          setIsLoader(false);
          console.log(err);
        }
      )
    );
  }, [dispatch, userId, updated]);

  const GST_RATE = 0.18;

  const calculateGST = (subtotal, gstRate) => {
    return subtotal * gstRate;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subtotal = cartItem?.reduce((acc, order) => {
    const quantity = Number(order.meal_quantity) || 0;
    const price = Number(order.food_price) || 0;
    return acc + (price * quantity);
  }, 0);

  useEffect(() => {
    const subtotal = cartItem?.reduce((acc, order) => {
      const quantity = Number(order.meal_quantity) || 0;
      const price = Number(order.food_price) || 0;
      return acc + (price * quantity);
    }, 0);

    setGst(calculateGST(subtotal, GST_RATE).toFixed(2));
    setSubTotal(subtotal);
    setTotal((subtotal + calculateGST(subtotal, GST_RATE)).toFixed(2));
  }, [cartItem]);

  return (
    <>
      <Navbar />

      <Grid container spacing={2} px={{ xs: 2, md: 5 }} sx={{ background: theme.palette.background.white, minHeight: '100vh', mt: 0 }}>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Checkout open={isModalOpen} handleClose={handleCloseModal} gst={gst} subTotal={subTotal} total={total} />
        <CustomLoader open={isLoader} setOpen={setIsLoader} />

        <Grid item xs={12} md={9}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '40%' }}>Product</TableCell>
                  <TableCell sx={{ width: '18%' }}>Price</TableCell>
                  <TableCell sx={{ width: '18%' }}>Quantity</TableCell>
                  <TableCell sx={{ width: '18%' }}>Total</TableCell>
                  <TableCell sx={{ width: '6%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItem && cartItem.length > 0 ? cartItem.map((order, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>
                        {order?.food_name}
                      </Typography>
                      <Typography>
                        <Box component={"span"} sx={{ fontWeight: "bold" }}>
                          Meal Type
                        </Box>{" "}
                        : {order?.meal_type}
                        <br />
                        <Box component={"span"} sx={{ fontWeight: "bold" }}>
                          Meal Plan
                        </Box>{" "}
                        : {order?.meal_plan}
                      </Typography>
                    </TableCell>
                    <TableCell>Rs. {order?.food_price}</TableCell>
                    <TableCell>{order?.meal_quantity}</TableCell>
                    <TableCell>Rs. {order?.food_price * order?.meal_quantity}</TableCell>
                    <TableCell>
                      <IconButton>
                        <DeleteIcon sx={{ fontSize: '1rem' }} fontSize="small" onClick={() => handleDelete(order?.id)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">No items in cart</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button
              disableElevation
              variant="contained"
              sx={{ marginTop: "10px", marginBottom: "20px", boxShadow: "none" }}
            >
              Update Cart <Autorenew />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box p={2} sx={{
            border: ".1rem dashed #d7d7d7",
            backgroundColor: "#f9f9f9",
            borderRadius: ".3rem",
          }}>
            <Typography>Cart Total</Typography>
            <hr />
            <Typography my={2}>SubTotal: <Box component={"span"} sx={{ float: "right" }}>Rs. {subtotal}</Box></Typography>
            <Typography my={2}>GST (18%): <Box component={"span"} sx={{ float: "right" }}>Rs. {calculateGST(subtotal, GST_RATE).toFixed(2)}</Box></Typography>
            <Typography my={2}>Total: <Box component={"span"} sx={{ float: "right" }}>Rs. {(subtotal + calculateGST(subtotal, GST_RATE)).toFixed(2)}</Box></Typography>
            <Button disableElevation onClick={handleOpenModal} variant="contained" sx={{ marginTop: '10px', boxShadow: "none" }} fullWidth>
              PROCEED TO CHECKOUT
            </Button>
          </Box>
          <Button disableElevation onClick={() => navigate("/meal-service")} variant="contained" sx={{ marginTop: '10px', marginBottom: '20px', boxShadow: "none", mt: 4 }} fullWidth>
            CONTINUE ORDERING <Autorenew />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Cart;
