import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';


const ShippingPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Navbar />
            <Container maxWidth="xl" sx={{ my: 10 }}>
                <Typography variant="h1" component="h1" gutterBottom>
                    Shipping Policy for The Joys Kitchen
                </Typography>
                <Typography variant="h6" gutterBottom>
                        Last Updated: Aug 21, 2024
                    </Typography>

                <Box mt={3}>
                    <Typography variant="body1" gutterBottom>
                        At The Joys Kitchen, we are committed to delivering your food promptly and in perfect condition. This Shipping Policy outlines how we handle the processing, shipping, and delivery of orders placed through our website www.thejoyskitchen.in.
                    </Typography>

                    <List>
                        <ListItem>
                            <Typography variant="h5">1. Shipping Coverage</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Service Area:</strong> We currently offer shipping to Hyderabad, Telangana. Please ensure that your delivery address falls within our service area before placing your order.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Delivery Locations:</strong> We deliver to both residential and business addresses. Please provide detailed delivery instructions if your location is difficult to find or requires special access.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">2. Shipping Fees</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Delivery Charges:</strong> Delivery charges are calculated based on your location and the delivery option selected at checkout. The exact fee will be displayed before you finalize your purchase.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Free Shipping:</strong> We offer free shipping on orders over minimum order value, e.g., ₹1000 within our standard delivery area.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">3. Delivery Timeframe</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Estimated Delivery:</strong> We strive to deliver your order within 5-6 minutes per km of placing it. Actual delivery times may vary based on the distance, demand for delivery services/tasks, real-time analysis of traffic and weather conditions, seasonal peaks, or other factors as determined from time to time. While we aim to meet the specified timeframe, we cannot guarantee exact delivery times.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Delivery Delays:</strong> While we aim to deliver on time, there may be occasional delays due to unforeseen circumstances. We will notify you if your order is delayed and work to resolve the issue as quickly as possible.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">4. Failed Deliveries</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Incorrect Address:</strong> Please ensure that your delivery address is accurate. We are not responsible for orders delivered to incorrect addresses provided by the customer.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Missed Deliveries:</strong> If no one is available to receive the order at the delivery address, our delivery driver will attempt to contact you. If the delivery cannot be completed, the order will be returned to our kitchen, and you may be responsible for additional delivery fees.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">5. Tracking Your Order</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Order Tracking:</strong> Once your order is dispatched, you will receive a confirmation email with a tracking number (if applicable). You can use this tracking number to monitor the status of your delivery.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">6. Delivery Partner</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Assigned by Kitchen:</strong> All deliveries will be handled by a delivery partner assigned by The Joys Kitchen. You will not be able to select a specific delivery partner during the checkout process. Rest assured, we work with reliable partners to ensure your order arrives safely and on time.
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography variant="h5">7. Contact Information</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                If you have any questions or concerns about our shipping policy, please contact us at:
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Email:</strong> chjoywilliams@gmail.com
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Phone:</strong> +91 70938 57806
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                <strong>Address:</strong> Shop No 397, Tellapur village, Tellapur,  Rangareddy, Hyderabad, Telangana, 502032
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
            </Container>


        </Box>
    );
};

export default ShippingPolicy;
