import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import theme from '../../../theme';
import footerImage1 from '../../../Images/footerImage1.svg';
import footerImage2 from '../../../Images/footerImage2.svg';
import footerImage3 from '../../../Images/footerImage3.svg';
import footerImage4 from '../../../Images/footerImage4.svg';
import footerImage5 from '../../../Images/footerImage5.svg';

const testimonials = [
    {
        text: "Amazing food! I look forward to office lunches, enjoying these delicious healthy meals with colleagues. They got the biryani right :) perfectly layered and fried onions on the top were amazing! Recommend them all.",
        name: "Brooklyn Simmons"
    },
    {
        text: "Have been ordering since about a year and no disappointment yet!!! Delicious food without excessive oil, masalas or fried stuff. Always delivered on time. Best part is food is fresh.",
        name: "Jenny Wilson"
    }
];

const TestimonialsCarousel = ({ title }) => {
    return (
        <Box>
            <Box sx={{ minHeight: { md: "100vh", xs: "50vh" }, display: 'flex', justifyContent: 'center', flexDirection: 'column' }} data-aos="fade-up" data-aos-once={true}>
                <Box sx={{ px: { xs: 3, sm: 6, md: 12 }, pt: { xs: 5, md: 10 }, pb: 3, background: theme.palette.background.default }}>
                    <Typography variant="h5"  sx={{ ml: { xs: 0, md: 13 }, color: theme.palette.text.black, textAlign: { xs: 'center', md: 'left' },  }}>
                        Testimonial
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'left' }, mb: 3, flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'initial' } }}>
                        <Divider sx={{ width: '5rem', background: theme.palette.background.black, height: '1px', mt: 3, mr: { xs: 0, md: 4 }, mb: { xs: 2, md: 0 } }} />
                        <Box>
                            <Typography variant="h1" sx={{ color: theme.palette.text.black, width: { xs: '100%', md: '25rem' }, textAlign: { xs: 'center', md: 'left' } }}>
                                {title}
                            </Typography>
                        </Box>
                    </Box>

                    <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay showStatus={false} showIndicators={false}>
                        {testimonials.map((testimonial, index) => (
                            <Box key={index} sx={{ padding: { xs: '10px', md: '20px' }, textAlign: { xs: 'center', md: 'left' } }}>
                                <Typography variant="h6" className='fontBeta' sx={{ fontStyle: 'italic', fontWeight:400 }}>
                                    {testimonial.text}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
                                    {testimonial.name}
                                </Typography>
                            </Box>
                        ))}
                    </Carousel>
                </Box>

            </Box>
            <Box sx={{ background: theme.palette.background.black, display: 'flex', flexWrap: 'wrap', justifyContent: { md: 'space-between', xs: "center" }, borderBottom: '1px solid #fff', py: 2, px: { md: 10, xs: 0 } }}>
                <img src={footerImage1} style={{ height: 'auto', maxWidth: '100%', margin: '0.5rem' }} alt="Footer Image 1" />
                <img src={footerImage2} style={{ height: 'auto', maxWidth: '100%', margin: '0.5rem' }} alt="Footer Image 2" />
                <img src={footerImage3} style={{ height: 'auto', maxWidth: '100%', margin: '0.5rem' }} alt="Footer Image 3" />
                <img src={footerImage4} style={{ height: 'auto', maxWidth: '100%', margin: '0.5rem' }} alt="Footer Image 4" />
                <img src={footerImage5} style={{ height: 'auto', maxWidth: '100%', margin: '0.5rem' }} alt="Footer Image 5" />
            </Box>
        </Box>

    );
};

export default TestimonialsCarousel;
