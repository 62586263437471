import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';


const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Navbar />
            <Container maxWidth="xl">
                <Box my={10}>
                    <Typography variant="h1" component="h1" gutterBottom>
                        Privacy Policy for The Joys Kitchen
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Last Updated: Aug 21, 2024
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The Joys Kitchen is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website{' '}
                        <Link to="https://www.thejoyskitchen.in">www.thejoyskitchen.in</Link>. By using our Website, you agree to the terms of this Privacy Policy.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        1. Information We Collect
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Personal Information:</strong> When you register on our Website, place an order, subscribe to our newsletter, or fill out a form, we may collect personal information such as your name, email address, mailing address, phone number, and payment details.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Non-Personal Information:</strong> We may also collect non-personal information automatically when you visit our Website, such as your IP address, browser type, device information, and pages viewed.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        2. How We Use Your Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>To Process Orders:</strong> We use your personal information to process and fulfill your orders, including sending you emails to confirm your order status and delivery updates.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>To Improve Our Website:</strong> We may use non-personal information to enhance your experience on our Website, including analyzing how you interact with our content and features.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>To Send Periodic Emails:</strong> With your consent, we may send you promotional emails, newsletters, or other marketing communications. You can opt out of these communications at any time.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        3. How We Protect Your Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We implement a variety of security measures to protect your personal information. These include secure servers, encrypted databases, and restricted access to your information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        4. Sharing Your Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
                    </Typography>
                    <List>
                        <Typography variant="body1" paragraph>
                            Service Providers: We may share your information with third-party service providers who assist us in operating our Website, processing payments, or delivering orders, provided they agree to keep your information confidential.
                        </Typography>
                        <Typography variant="body1" paragraph>
                           Legal Requirements: We may disclose your information when required by law or to protect our rights, property, or safety.
                        </Typography>

                    </List>

                    <Typography variant="h5" component="h2" gutterBottom>
                        5. Cookies and Tracking Technologies
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our Website uses cookies and other tracking technologies to improve your experience, track user activity, and gather demographic information. You can choose to disable cookies through your browser settings, but this may affect the functionality of the Website.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        6. Third-Party Links
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to read the privacy policies of any third-party sites you visit.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        7. Your Rights
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You have the right to access, correct, update, or delete your personal information at any time. To exercise these rights, please contact us at chjoywilliams@gmail.com.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        8. Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised. Your continued use of the Website after any changes signifies your acceptance of the updated policy.
                    </Typography>

                    <Typography variant="h5" component="h2" gutterBottom>
                        9. Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions or concerns about this Privacy Policy, please contact us at:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Email: chjoywilliams@gmail.com
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Address: Shop No 397, Tellapur village, Tellapur,  Rangareddy, Hyderabad, Telangana, 502032
                    </Typography>
                </Box>
            </Container>


        </Box>
    );
};

export default PrivacyPolicy;
