import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import mealImage from "../Images/mealImage.jpg";
import theme from "../theme";
import MealViewModal from "./MealViewModal";

const MealCard = ({ item }) => {
  const [value, setValue] = useState(2);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  return (
    <Box
      sx={{
        p: 3,
        background: "#fff",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        textAlign: 'left',
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "100%", // This makes the Box a square
          position: "relative",
          borderRadius: "1rem",
          overflow: "hidden",
          height: 'auto'
        }}
      >
        <img
          src={`data:image/png;base64,${item?.food_image}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Typography variant="h5" className="fontAlpha" sx={{ mt: 1, fontSize: { md: '1.25rem', xs: '1.75rem' } }}>
        {item?.food_name}
      </Typography>

      <Rating
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        readOnly
        sx={{ fontSize: { md: '1.3rem', xs: '1.5rem' } }}
      />

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          color: theme.palette.text.lightBlack,
          fontSize: { md: '0.75rem', xs: '1rem' }
        }}
      >
        {item?.food_description}
      </Typography>

      <Box className="center" sx={{ mt: 4 }}>
        <Typography
          variant="body2"
          className="fontAlpha"
          sx={{
            mt: 1,
            color: theme.palette.text.black,
            fontWeight: 600,
            fontSize: { md: '0.875rem', xs: '1.1rem' }

          }}
        >
          Rs. {item?.food_price}
        </Typography>

        <Button
          variant="outlined"
          className="fontBeta"
          sx={{ height: "1.8rem", width: 'content-fit' }}
          onClick={() => setOpen(true)}
        >
          <Typography variant="body2" className="fontBeta" sx={{fontSize: { md: '0.875rem', xs: '1.1rem' }}}>
            Quick view
          </Typography>
        </Button>
      </Box>
      <MealViewModal open={open} handleClose={handleClose} item={item} />
    </Box>
  );
};

export default MealCard;
