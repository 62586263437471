import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const UnSuccessfullOrder = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const orderId = "123456";
    const restaurantName = "Tasty Treats";

    return (
        <Box>
        <Navbar />
        <Container maxWidth="sm" sx={{ textAlign: 'center', my: 10 }}>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                <ErrorOutlineIcon style={{ fontSize: 100, color: '#f44336' }} />
            </Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Order Unsuccessful
            </Typography>
            <Typography variant="body1" gutterBottom>
                Unfortunately, there was an issue processing your order from {restaurantName}.
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                Please try again or contact support for assistance.
            </Typography>
            <Button 
                variant="contained" 
                color="error" 
                href="/retry-payment" 
                style={{ marginTop: '20px' }}
            >
                Retry Payment
            </Button>
            <Button 
                variant="outlined" 
                href="/meal-service" 
                style={{ marginTop: '20px', marginLeft: '10px' }}
            >
                Browse More Dishes
            </Button>
        </Container>
    </Box>
    );
};

export default UnSuccessfullOrder;
