import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import Banner from '../../Components/Banner.js';
import MealCard from '../../Components/MealCard.js';
import theme from '../../theme.js';
// import Discription from './Component/Discription.js';
import MealServiceMain from '../../Images/HomeImage.jpg';
import { useDispatch } from 'react-redux';
import { getAllFoodItems } from '../../api/api/action.js';

const MealService = () => {
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);
    const [meals, setMeals] = useState([]);
    const [filteredMeals, setFilteredMeals] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Breakfast');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMealClick = (value) => {
        setSelectedValue(value);
    };

    const mealsTime = [
        { name: "Breakfast", value: "Breakfast" },
        { name: "Lunch", value: "Lunch" },
        { name: "Dinner", value: "Dinner" },
        { name: "Royal Meal Box", value: "Royal Meal Box" },
        { name: "Rice Bowl", value: "Rice Bowl" },
        { name: "Add Ons", value: "Add Ons" }
    ];

    console.log(selectedValue)


    useEffect(() => {
        const payload = null;

        setIsLoader(true);

        dispatch(
            getAllFoodItems(payload,
                (data) => {
                    setIsLoader(false);
                    setMeals(data?.data);
                },
                (err) => {
                    setIsLoader(false);
                    console.log(err);
                }
            )
        );
    }, [dispatch]);

    useEffect(() => {
        const filtered = meals.filter(meal => meal.food_category === selectedValue);
        setFilteredMeals(filtered);
    }, [meals, selectedValue]);

    return (
        <Box>
            <Navbar />

            <Banner title={"Meal Service"} image={MealServiceMain} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '100vh' }}>
                {/* <Box sx={{ textAlign: 'center', mt: { xs: 5, md: 10 }, display: "flex", justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography variant='h5' sx={{ ml: 1 }}>Breakfast -</Typography>
                    <Typography variant='body1' sx={{ ml: 1 }}>Upto 10PM previous day,</Typography>
                    <Typography variant='h5' sx={{ ml: 1 }}>Lunch -</Typography>
                    <Typography variant='body1' sx={{ ml: 1 }}>Upto 10AM same day,</Typography>
                    <Typography variant='h5' sx={{ ml: 1 }}>Dinner -</Typography>
                    <Typography variant='body1' sx={{ ml: 1 }}>Upto 5PM same day</Typography>
                </Box> */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: {md:'space-between', xs:"center"},
                    background: theme.palette.background.black,
                    py: 1.5,
                    borderRadius: "1rem",
                    px: { xs: 2, md: 3 },
                    flexWrap: 'wrap',
                    mt: { md: 3, xs: 3 },
                    mx: {xs:3 }
                }}>
                    {mealsTime.map((item, index) => (
                        <Box key={index} sx={{ position: 'relative', textAlign: 'center', mx: { xs: 1, md: 5 }, my: { xs: 1, md: 0 } }}>
                            <Typography
                                className='fontAlpha'
                                variant={{ lg: 'h5', md: 'h5', xs: "caption" }}
                                onClick={() => handleMealClick(item.value)}
                                sx={{
                                    color: selectedValue === item.value ? theme.palette.text.default : theme.palette.text.white,
                                    fontWeight: selectedValue === item.value ? 600 : 400,
                                    cursor: 'pointer',
                                    mb: selectedValue === item.value ? '4px' : '0'
                                }}
                            >
                                {item.name}
                            </Typography>
                            {selectedValue === item.value && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: -2,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: { xs: '110%', lg: '150%' },
                                        height: '2px',
                                        backgroundColor: theme.palette.text.default,
                                        borderRadius: '1rem'
                                    }}
                                />
                            )}
                        </Box>
                    ))}
                </Box>

                <Grid container spacing={3} sx={{ px: { xs: 2, md: 5 }, mt: 1, mb: 10 }} data-aos="fade-up" data-aos-once={true}>
                    {filteredMeals.length === 0 ? (
                        <Typography variant='h6' sx={{ width: '100%', textAlign: 'center', mt: 10 }}>No items available</Typography>
                    ) : (
                        filteredMeals.map((dish, index) => (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                                <MealCard item={dish} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default MealService;
