import React from 'react';
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme';


const Brief = ({ brief }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{ my: 10, px: {md:10, xs:3} }} >
            {/* <Typography variant="body1" sx={{ ml: 13 }}>
                Meal Option
            </Typography> */}

            <Box sx={{ display: { md: 'flex', xs: "block" }, justifyContent: 'left', }}>
                {(!isMobile) && (
                    <Divider sx={{ width: '5rem', background: theme.palette.background.black, height: '1px', mt: 3, mr: 4 }} />
                )}
                <Box>
                    <Typography variant="h1" sx={{ color: theme.palette.text.black, mb: 2 }}>
                        Catering Service in Hyderabad
                    </Typography>
                    <Typography variant={{ md: "h6", xs: "caption" }} className='fontBeta' sx={{ color: theme.palette.text.black, textAlign: 'left', fontWeight: 400 }}>
                        {brief}
                    </Typography>
                </Box>


            </Box>

        </Box >
    );
};

export default Brief;
