import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Home from './Pages/Home/Home';
// import About from './Pages/About';
// import NotFound from './Pages/NotFound';
import Navbar from '../src/Components/Navbar';
import theme from '../src/theme'; // Import your custom theme
import AboutUs from './Pages/AboutUs/AboutUs';
import Footer from './Components/Footer';
import Cart from './Pages/Cart/Cart';
import MealService from './Pages/MealService.js/MealService';
import CateringService from './Pages/CateringService/CateringService';
import FAQ from '../src/Pages/FAQ/FAQ';
import Checkout from '../src/Pages/Checkout/Checkout';
import Aos from 'aos';
import 'aos/dist/aos.css';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy/RefundPolicy';
import ShippingPolicy from './Pages/ShippingPolicy/ShippingPolicy';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import SuccessfullOrder from './Pages/SuccessfullOrder/SuccessfullOrder';
import UnSuccessfullOrder from './Pages/UnSuccessfullOrder/UnSuccessfullOrder';
import AdminLogin from './Pages/AdminLogin/AdminLogin';

function App() {

  useEffect(() => {
    Aos.init({ duration: 1400 });
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route exact path="/meal-service" element={<MealService />} />
            <Route exact path="/catering-service" element={<CateringService />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/refund-policy" element={<RefundPolicy />} />
            <Route exact path="/shipping-policy" element={<ShippingPolicy />} />
            <Route exact path="/terms&conditions" element={<TermsAndConditions />} />
            <Route exact path="/successful" element={<SuccessfullOrder />} />
            <Route exact path="/not-successful" element={<UnSuccessfullOrder />} />
            <Route exact path="/admin-login" element={<AdminLogin />} />
            {/* <Route exact path="/checkout" element={<Checkout/>} /> */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
