import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Navbar from '../../Components/Navbar.js';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessfullOrder = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const orderId = "123456";
    const restaurantName = "Tasty Treats";

    return (
        <Box>
            <Navbar />
            <Container maxWidth="sm" sx={{ textAlign: 'center', my: 10 }}>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                <CheckCircleIcon style={{ fontSize: 100, color: '#4caf50' }} />
            </Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Order Confirmed!
            </Typography>
            <Typography variant="body1" gutterBottom>
                Thank you for your order from {restaurantName}. Your payment has been successfully processed.
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                {/* Your Order ID: <strong>{orderId}</strong> */}
            </Typography>
            <Button 
                variant="customVariant" 
                href="/" 
                style={{ marginTop: '20px' }}
            >
                Go to Homepage
            </Button>
            <Button  
                variant="outlined" 
                href="/meal-service" 
                style={{ marginTop: '20px', marginLeft: '10px' }}
            >
                Browse More Dishes
            </Button>
        </Container>


        </Box>
    );
};

export default SuccessfullOrder;
